/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as vendorstock from '../redux/VendorStockRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {
  getVendorRequestData,
  getVendorSearchRequestData,
  vendorApprovalRequestApi,
} from '../redux/VendorStockCRUD'
import {config} from '../../../comman/constants'
import {History, LocationState} from 'history'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {Tag} from 'antd'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
} from '../../../comman/helper'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {VendorRejectReason} from './VendorRejectReason'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  tabkey: any
  vendorStockData: any
  vendorStockDataTotal: number
  vendorStockLoading: boolean
  setListVendorStockRequestData: (vendorStockData: any) => void
  setListVendorStockRequestDataTotal: (vendorStockDataTotal: number) => void
  setVendorStockLoading: (vendorStockLoading: boolean) => void
  history?: History<LocationState>
  loginUser: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  vendorRequestSelectedData: any
  vendorReasonModal: any
}

type PathParamsType = {
  param1: string
}
class ListVendorStockRequestData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      vendorRequestSelectedData: null,
      vendorReasonModal: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListVendorRequestData(pagination)
    )
  }

  public vendorActionApproval = async (item: any, status: boolean) => {
    const {searchText, startIndex, limitNumber} = this.state
    const {setVendorStockLoading} = this.props
    try {
      let reqObj: any = {}
      reqObj.stock_id = item.stock_id ? item.stock_id : ''
      reqObj.vendor_id = item.vendor_id ? item.vendor_id : ''
      reqObj.buy_request_id = item.buy_request_id ? item.buy_request_id : ''
      reqObj.is_available = status ? true : false

      await setVendorStockLoading(true)

      let aprrovalData = await vendorApprovalRequestApi(reqObj)

      if (aprrovalData && aprrovalData.status == 200) {
        Toaster({
          type: 'success',
          title: 'STOCK REQUEST',
          description: 'Action Taken SuccessFully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'STOCK REQUEST',
          description: 'Something Went Wrong',
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      if (searchText && searchText !== '') {
        this.listVendorRequestSearchData(startIndex, limitNumber)
      } else {
        this.listVendorRequestData(startIndex, limitNumber)
      }
      await setVendorStockLoading(true)
    }
  }

  public getListVendorRequestData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listVendorRequestSearchData(start, end)
        } else {
          this.listVendorRequestData(start, end)
        }
      }
    )
  }

  public listVendorRequestData = async (start: number, end: any) => {
    const {setVendorStockLoading} = this.props
    try {
      const {setListVendorStockRequestData, setListVendorStockRequestDataTotal, loginUser, tabkey} =
        this.props
      await setVendorStockLoading(true)

      let vendorStockData = await getVendorRequestData(start, end, tabkey)
      if (vendorStockData.data) {
        await setListVendorStockRequestData(vendorStockData.data)
        await setListVendorStockRequestDataTotal(vendorStockData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setVendorStockLoading(false)
    }
  }

  public listVendorRequestSearchData = async (start: number, end: any) => {
    const {setVendorStockLoading, tabkey} = this.props
    try {
      const {setListVendorStockRequestData, setListVendorStockRequestDataTotal} = this.props
      const {searchText} = this.state

      await setVendorStockLoading(true)
      let vendorStockData = await getVendorSearchRequestData(start, end, searchText, tabkey)
      if (vendorStockData.data) {
        await setListVendorStockRequestData(vendorStockData.data)
        await setListVendorStockRequestDataTotal(vendorStockData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setVendorStockLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listVendorRequestSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public showReasonModalModal = (item: any) => {
    this.setState({
      vendorReasonModal: item,
    })
  }

  public closeReasonModal = async () => {
    const {searchText, startIndex, limitNumber} = this.state
    const {setVendorStockLoading} = this.props
    this.setState({
      vendorReasonModal: null,
    })
    if (searchText && searchText !== '') {
      this.listVendorRequestSearchData(startIndex, limitNumber)
    } else {
      this.listVendorRequestData(startIndex, limitNumber)
    }
    await setVendorStockLoading(true)
  }

  counter = 0
  tempCounter = 0
  public pendingColumns = [
    // {
    //   title: 'Sr. No.',
    //   key: 'serial_number',
    // fixed: window.innerWidth < 768 ? false : true,
    //   width: 60,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <span>{++index + this.tempCounter}</span>
    //       </div>
    //     )
    //   },
    // },
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'Stock Id',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a href={`/inventory/preview/${item.stock.stock_id}`} target='_blank'>
              <strong>
                {item.stock && item.stock.stock_id ? returnStockId(item.stock.stock_id) : '-'}
              </strong>
            </a>
          </div>
        )
      },
    },
    {
      // title: 'Status',
      title: 'STATUS',
      key: 'status',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.stock && item.stock.status ? (
              <Tag
                color={
                  config.statusTagColor[item.stock.status]
                    ? config.statusTagColor[item.stock.status]
                    : 'default'
                }
              >
                {item.stock.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.shape ? returnStockId(item.stock.shape) : '-'}</div>
      },
    },
    // {
    //   // title: 'Weight',
    //   title: 'WEIGHT',
    //   key: 'weight',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.weight ? item.weight : '-'}</div>
    //   },
    // },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.color ? returnStockId(item.stock.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : '-'}</div>
        )
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.cut ? returnStockId(item.stock.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.polish ? returnStockId(item.stock.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : '-'}</div>
        )
      },
    },

    {
      // title: 'Fluorescence Intensity',
      title: 'FL',
      // ellipsis: true,
      key: 'fluorescence_intensity',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.stock && item.stock.fluorescence_intensity
              ? returnStockId(item.stock.fluorescence_intensity)
              : '-'}
          </div>
        )
      },
    },
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.price_per_caret ? item.stock.price_per_caret : '-'}</div>
        )
      },
    },
    {
      // title: 'Price Per Caret',
      title: 'Action Taken',
      // ellipsis: true,
      key: 'is_action_taken',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_action_taken && item.is_available ? (
              <Tag color='green'>Approved</Tag>
            ) : item.is_action_taken && !item.is_available ? (
              <Tag color='red'>Rejected</Tag>
            ) : (
              <Tag color='gold'>Pending</Tag>
            )}
          </div>
        )
      },
    },
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
           {this.props.loginUser.role === 'vendor' ? 
            item.stock && item.stock.final_price_ori
            ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price_ori)
            : '-'
            : item.stock && item.stock.final_price
              ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price)
              : '-'}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 140,
      key: 'action',
      // fixed: 'right',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            {!item.is_action_taken && (
              <>
                <div style={{float: 'left', marginRight: '5px'}}>
                  <Tooltip placement='bottom' title='Reject Stock Request'>
                    {/* <Popconfirm
                      onConfirm={() => {
                        //call delete client api
                        this.vendorActionApproval(item, false)
                      }}
                      title='Are you sure you want to reject Stock Request?'
                    > */}
                    <Button
                      shape='circle'
                      icon={<CloseOutlined />}
                      // onClick={() => {
                      //   this.vendorActionApproval(item, false)
                      // }}
                      onClick={() => {
                        this.showReasonModalModal(item)
                      }}
                    />
                    {/* </Popconfirm> */}
                  </Tooltip>
                </div>
                <div style={{float: 'left', marginRight: '5px'}}>
                  <Tooltip placement='bottom' title='Approve Stock Request'>
                    <Popconfirm
                      onConfirm={() => {
                        //call delete client api
                        this.vendorActionApproval(item, true)
                      }}
                      title='Are you sure you want to approve Stock Request?'
                    >
                      <Button
                        shape='circle'
                        icon={<CheckOutlined />}
                        // onClick={() => {
                        //   this.vendorActionApproval(item, true)
                        // }}
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        )
      },
    },
  ]

  public columns = [
    // {
    //   title: 'Sr. No.',
    //   key: 'serial_number',
    // fixed: window.innerWidth < 768 ? false : true,
    //   width: 60,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <span>{++index + this.tempCounter}</span>
    //       </div>
    //     )
    //   },
    // },
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'Stock Id',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a href={`/inventory/preview/${item.stock.stock_id}`} target='_blank'>
              <strong>
                {item.stock && item.stock.stock_id ? returnStockId(item.stock.stock_id) : '-'}
              </strong>
            </a>
          </div>
        )
      },
    },
    {
      // title: 'Status',
      title: 'STATUS',
      key: 'status',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.stock && item.stock.status ? (
              <Tag
                color={
                  config.statusTagColor[item.stock.status]
                    ? config.statusTagColor[item.stock.status]
                    : 'default'
                }
              >
                {item.stock.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.shape ? returnStockId(item.stock.shape) : '-'}</div>
      },
    },
    // {
    //   // title: 'Weight',
    //   title: 'WEIGHT',
    //   key: 'weight',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.weight ? item.weight : '-'}</div>
    //   },
    // },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.color ? returnStockId(item.stock.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : '-'}</div>
        )
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.cut ? returnStockId(item.stock.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.polish ? returnStockId(item.stock.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : '-'}</div>
        )
      },
    },

    {
      // title: 'Fluorescence Intensity',
      title: 'FL',
      // ellipsis: true,
      key: 'fluorescence_intensity',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.stock && item.stock.fluorescence_intensity
              ? returnStockId(item.stock.fluorescence_intensity)
              : '-'}
          </div>
        )
      },
    },
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.price_per_caret ? item.stock.price_per_caret : '-'}</div>
        )
      },
    },
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {this.props.loginUser.role === 'vendor' ? 
            item.stock && item.stock.final_price_ori
            ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price_ori)
            : '-'
            : item.stock && item.stock.final_price
              ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price)
              : '-'}
          </div>
        )
      },
    },
  ]

  public render() {
    const {vendorStockData, vendorStockDataTotal, vendorStockLoading, loginUser, tabkey} =
      this.props
    const {vendorRequestSelectedData, vendorReasonModal} = this.state

    const columnExists = (key: any) => {
      return this.columns.some((column) => column.key === key)
    }

    if (!columnExists('reject_reason') && tabkey == 'CANCELED') {
      this.columns.push({
        title: 'Reason',
        key: 'reject_reason',
        width: 100,
        render: (text: any, item: any, index: number) => {
          return (
            <Tooltip placement='bottom' title={item.reject_reason ? item.reject_reason : '-'}>
              <div className='d-inline-block text-truncate' style={{maxWidth: '100px'}}>
                {' '}
                {item.reject_reason ? item.reject_reason : '-'}
              </div>
            </Tooltip>
          )
        },
      })
    }
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div style={{overflowX: 'auto'}}>
              <ListTable
                rowKey={(record: {_id: any}) => record._id}
                onChange={this.handleTableChange}
                onPaginationChange={(e: any) => {
                  if (e - 1) {
                    this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                    return
                  }

                  this.counter = 0
                  this.tempCounter = 0
                }}
                column={tabkey !== 'PENDING' ? this.columns : this.pendingColumns}
                paginationConfig={{
                  total: vendorStockDataTotal,
                  showSizeChanger: true,
                  pageSize: this.state.pagination.pageSize,
                  current: this.state.pagination.current,
                }}
                isLoading={vendorStockLoading}
                dataSource={vendorStockData}
                counter={this.counter}
                tempCounter={this.tempCounter}
                isScroll={true}
                scrollWidth={700}
              />
            </div>
          </div>
        </div>
        <CommanModal
          show={vendorReasonModal ? true : false}
          handleClose={() => {
            this.closeReasonModal()
          }}
          title={'Reject Reason'}
          submitText={'Save Changes'}
          isScrollable={true}
        >
          <div>
            {vendorReasonModal && (
              <VendorRejectReason
                data={vendorReasonModal}
                handleCancel={() => {
                  this.closeReasonModal()
                }}
              />
            )}
          </div>
        </CommanModal>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  vendorStockData: state.vendorstock.vendorStockData,
  vendorStockDataTotal: state.vendorstock.vendorStockDataTotal,
  vendorStockLoading: state.vendorstock.vendorStockLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListVendorStockRequestData: (vendorRequestData: any) => {
      dispatch(vendorstock.actions.setVendorStockData(vendorRequestData))
    },
    setListVendorStockRequestDataTotal: (vendorRequestDataTotal: number) => {
      dispatch(vendorstock.actions.setVendorStockDataTotal(vendorRequestDataTotal))
    },
    setVendorStockLoading: (vendorRequestLoading: boolean) => {
      dispatch(vendorstock.actions.setVendorStockLoading(vendorRequestLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListVendorStockRequestData))
