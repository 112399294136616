/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWraaper} from './container/List'
import {CreateWrapper} from './container/CreateBanner'

export function BannerPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/banner/list' exact={true} component={ListWraaper} />
        <Route path='/banner/create' exact={true} component={CreateWrapper} />
        <Redirect from='/banner' exact={true} to='/banner/list' />
        <Redirect to='/banner/list' />
      </Switch>
    </div>
  )
}
