import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
import {Tag} from 'antd'
// import {getShipMentPriceList} from '../../shipmentPrice/redux/ShipmentPriceCRUD'
import {editBuyRequestData, getBuyRequestStockDetails} from '../redux/BuyRequestCRUD'
import {Popconfirm} from 'antd'
import {Spin} from 'antd'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {Tooltip} from 'antd'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {Switch} from 'antd'
import {connect} from 'react-redux'
import {config} from './../../../comman/constants'

interface IRenderFormikForm {
  values: IBuyRequestFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

const PRIMARY_COLOR = config.buttonPrimaryColor

export interface IBuyRequestFormValues {
  status?: string
  diamonds?: {stock_id: number; is_available: boolean}
}

interface IEditBuyRequestProps extends RouteComponentProps<any> {
  onSubmit: any
  data: any
  handleCancel?: any
  loginUserData: any
  componentRef: any
}

class PrintBuyRequestPreviewComponent extends React.PureComponent<IEditBuyRequestProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      updatedStockData: [],
      clientDataState: {},
      shipmentAddressDataState: {},
      billingAddressDataState: {},
      approveButtonState: false,
      apiResonseData: null,
    }
  }

  totalPrice = 0
  totalDiscount = 0
  shipMentPrice = 0

  buyRequestStatus = [
    {label: 'Accepted', value: 'ACCEPTED'},
    {label: 'Cancelled', value: 'CANCELLED'},
    {label: 'Updated', value: 'UPDATED'},
    {label: 'Pending', value: 'PENDING'},
  ]

  stockAvailableStatus = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ]

  public validateForm = (values: IBuyRequestFormValues) => {
    const errors: any = {}

    console.log(errors)

    return errors
  }

  public handleSubmit = (values: IBuyRequestFormValues, action: any) => {
    const {data, handleCancel, onSubmit} = this.props
    values.diamonds = this.state.updatedStockData
    onSubmit(values, data._id)
    handleCancel()

    this.setState({
      visible: false,
      shipmentData: [],
    })
  }

  componentDidMount() {
    this.callInitialData()
    // await this.initialStockStatus()
  }

  initialStockStatus = () => {
    const {data} = this.props

    // if (data && data.stock_diamonds.length >= 1) {
    //   let tempStockData: any = []
    //   data.stock_diamonds.map((stockItem: any, index: number) => {
    //     tempStockData.push({
    //       _id: stockItem._id,
    //       is_available: stockItem.is_available,
    //       stock_id: stockItem.stock_id.stock_id,
    //       stockId_id: stockItem.stock_id._id,
    //       stock_data: stockItem.stock_id,
    //     })
    //   })
    //   this.setState({updatedStockData: tempStockData})
    // }
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      const {data} = this.props
      // let shipMentList = await getShipMentPriceList()
      // if (shipMentList.data) {
      //   this.setState({shipmentData: shipMentList.data})
      // }
      this.setState({
        isLoading: true,
      })
      const {match} = this.props
      const itemId = match.params.id

      let stockDetails: any = await getBuyRequestStockDetails(itemId)

      if (
        stockDetails &&
        stockDetails.data &&
        stockDetails.data.stock_ids &&
        stockDetails.data.stock_ids.length
      ) {
        // let tempStockData: any = []
        // stockDetails.data.stock_ids.map((stockItem: any, index: number) => {
        //   tempStockData.push({
        //     _id: stockItem.stock_id,
        //     is_available: stockItem.is_available,
        //     stock_id: stockItem.stock.stock_id,
        //     // stockId_id: stockItem.stock_id._id,
        //     stock_data: stockItem.stock.stock_id,
        //   })
        // })
        const allApproved = stockDetails.data.stock_ids.every(
          (stock: any) => stock.is_action_taken && stock.is_available
        )

        this.setState({
          apiResonseData: stockDetails.data,
          updatedStockData: stockDetails.data.stock_ids,
          shipmentData:
            stockDetails.data && stockDetails.data.shipment_price
              ? stockDetails.data.shipment_price
              : 0,
          clientDataState: stockDetails.data.user ? stockDetails.data.user : {},
          shipmentAddressDataState: stockDetails.data.shipping_address
            ? stockDetails.data.shipping_address
            : {},
          billingAddressDataState: stockDetails.data.billing_address
            ? stockDetails.data.billing_address
            : {},
          approveButtonState: allApproved,
        })
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public updateBuyRequest = async (status: any) => {
    const {history} = this.props
    const {updatedStockData} = this.state
    try {
      this.setState({
        isLoading: true,
      })
      if (updatedStockData && updatedStockData.length >= 1) {
        let formValues: any = {}

        // formValues.status = status

        // if (status === 'UPDATED') {
        let finalCartValue: any = []

        console.log(updatedStockData, 'updatedStockData')
        updatedStockData.map((item: any) => {
          const id = item.vendor_id ? item.vendor_id : item.admin_id
          finalCartValue.push({
            stock_id: item.stock_id,
            [item.vendor_id ? 'vendor_id' : 'admin_id']: id,
            is_available: item.is_available,
            is_action_taken: item.is_action_taken,
          })
        })
        // formValues.diamonds = finalCartValue
        // }

        // if (status === 'PENDING') {
        //   let finalCartValue: any = []

        //   updatedStockData
        //     .filter((item: any) => item.is_available)
        //     .map((item: any) =>
        //       finalCartValue.push({stock_id: item.stock_id, is_available: item.is_available})
        //     )
        //   formValues.diamonds = finalCartValue
        // }

        console.log('finalCartValue', finalCartValue)
        const {match} = this.props
        const itemId = match.params.id

        //   onSubmit(finalCartValue, itemId, status)
        let editResponse = await editBuyRequestData(finalCartValue, itemId, status)

        if (editResponse && editResponse.status == 200) {
          Toaster({
            type: 'success',
            title: 'BUY REQUEST',
            description: 'Updated SuccessFully',
          })
        } else {
          Toaster({
            type: 'error',
            title: 'BUY REQUEST',
            description: 'Something Went Wrong',
          })
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        isLoading: false,
      })
      history.push(`${process.env.PUBLIC_URL}/buy-request/list`)
    }
    // handleCancel()

    // this.setState({
    //   visible: false,
    // })
  }

  public navigateToPage = (path: string) => {
    const {history} = this.props
    history.push(`${process.env.PUBLIC_URL}${path}`)
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public onChangeStockUpdate = (stockId: string, status: string) => {
    let dataExistsIndex = -1
    if (this.state.updatedStockData && this.state.updatedStockData.length >= 1) {
      dataExistsIndex = this.state.updatedStockData.findIndex(
        (item: any) => item.stock_id === stockId
      )
    }
    if (dataExistsIndex === -1) {
      this.setState({
        updatedStockData: [...(this.state.updatedStockData || {}), {is_available: status}],
      })
    } else if (dataExistsIndex >= 0) {
      this.state.updatedStockData[dataExistsIndex].is_available = status
      this.setState({
        updatedStockData: [...this.state.updatedStockData],
      })
    }
  }

  public calculateTotal = () => {
    const {data} = this.props
    const {updatedStockData} = this.state

    // if (data && data.stock_diamonds && data.stock_diamonds.length >= 1) {
    //   this.totalPrice = 0
    //   this.totalDiscount = 0
    //   data.stock_diamonds.map((invItem: any) => {
    //     this.totalPrice += invItem.stock_id.final_price
    //     this.totalDiscount += invItem.stock_id.discounts
    //   })
    // }
    if (updatedStockData && updatedStockData.length >= 1) {
      this.totalPrice = 0
      this.totalDiscount = 0
      updatedStockData.map((invItem: any) => {
        this.totalPrice += invItem.stock.final_price
        this.totalDiscount += invItem.stock.discounts
      })
    }
    return returnPriceInThousandFormatWithCurrencySign(this.totalPrice)
  }

  public calculateAvgDiscount = () => {
    const {updatedStockData} = this.state

    // return returnPriceInThousandFormat(this.totalDiscount / data.stock_diamonds.length)
    return returnPriceInThousandFormat(this.totalDiscount / updatedStockData.length)
  }

  public calculateShipMent = () => {
    const {shipmentData} = this.state

    // if (shipmentData && shipmentData.length >= 1) {
    //   let getShipMentData = shipmentData.find(
    //     (shipItem: any) =>
    //       shipItem.from_number < this.totalPrice && shipItem.to_number > this.totalPrice
    //   )
    //   if (getShipMentData) {
    //     this.shipMentPrice = getShipMentData.price
    //   }
    // }
    this.shipMentPrice = shipmentData ? shipmentData : 0
    return returnPriceInThousandFormatWithCurrencySign(this.shipMentPrice)
  }

  public calculateGrandTotal = () => {
    let grandTotal = this.shipMentPrice + this.totalPrice
    return returnPriceInThousandFormatWithCurrencySign(grandTotal)
  }

  public render() {
    const {loginUserData} = this.props
    const {
      updatedStockData,
      clientDataState,
      shipmentAddressDataState,
      billingAddressDataState,
      approveButtonState,
      apiResonseData,
      stockDetails,
    } = this.state

    const {role} = loginUserData

    return (
      <div className='card card-custom'>
        <Formik initialValues={{}} validate={this.validateForm} onSubmit={this.handleSubmit}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }: IRenderFormikForm) => {
            return (
              <Spin spinning={this.state.isLoading} tip='Loading...'>
                <div ref={this.props.componentRef}>
                  <style type='text/css' media='print'>
                    {`
            @page {
              size: landscape;
              width: 1400px;
            }
          `}
                  </style>
                  <div className='card-body'>
                    <>
                      {role !== 'vendor' && (
                        <div className='mb-6' style={{position: 'relative'}}>
                          <h3
                            className='font-weight-bold'
                            style={{marginBottom: '0', lineHeight: '150%'}}
                          >
                            {/* {'Buy Request Details'} */}
                            {clientDataState
                              ? clientDataState?.legal_registered_name
                                ? clientDataState.legal_registered_name.toUpperCase()
                                : '-'
                              : '-'}
                          </h3>
                          <span style={{fontWeight: 500}}>
                            {clientDataState
                              ? clientDataState?.first_name && clientDataState?.last_name
                                ? `${clientDataState.first_name} ${clientDataState.last_name}`
                                : clientDataState.first_name
                                ? clientDataState.first_name
                                : '-'
                              : '-'}
                            {' | '}
                            {clientDataState
                              ? clientDataState?.email
                                ? clientDataState.email
                                : '-'
                              : '-'}
                          </span>
                          <div
                            className='right-side-details'
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 0,
                            }}
                          >
                            <div>
                              {' '}
                              <h3
                                className='font-weight-bold text-right'
                                style={{marginBottom: '0', lineHeight: '150%'}}
                              >
                                Buy Request
                              </h3>
                            </div>
                            <span style={{fontWeight: 500}}>
                              {' '}
                              {apiResonseData && apiResonseData.order_code
                                ? apiResonseData.order_code
                                : '-'}
                              {' | '}
                              {apiResonseData && apiResonseData.createdAt
                                ? moment(apiResonseData.createdAt).format('DD-MM-YYYY')
                                : '-'}
                            </span>
                          </div>
                          {/* <span className='ml-5'>
                              {data?.status && (
                                <Tag
                                  color={
                                    config.statusTagColor[data.status]
                                      ? config.statusTagColor[data.status]
                                      : 'default'
                                  }
                                >
                                  {data.status}
                                </Tag>
                              )}
                            </span> */}

                          {/* <div className='row mb-5'>
                            <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                              {'Name:'}
                            </div>
                            <div className='col-lg-4 font-weight-bold'>
                              {clientDataState
                                ? clientDataState?.first_name && clientDataState?.last_name
                                  ? `${clientDataState.first_name} ${clientDataState.last_name}`
                                  : clientDataState.first_name
                                  ? clientDataState.first_name
                                  : '-'
                                : '-'}
                            </div>
                            <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                              {'Company:'}
                            </div>
                            <div className='col-lg-4 font-weight-bold'>
                              {clientDataState
                                ? clientDataState?.legal_registered_name
                                  ? clientDataState.legal_registered_name
                                  : '-'
                                : '-'}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                              {'Email:'}
                            </div>
                            <div className='col-lg-4 font-weight-bold'>
                              {clientDataState
                                ? clientDataState?.email
                                  ? clientDataState.email
                                  : '-'
                                : '-'}
                            </div>
                          </div> */}
                        </div>
                      )}
                      <div className='row no-gutters pt-4' style={{borderTop: '1px solid #eff2f5'}}>
                        <div className='col-lg-6 col-md-6 col-sm-12 mb-4 ml-2 text-muted'>
                          <h3 className='mb-2'>{'Billing Address'}</h3>
                          <span>
                            {billingAddressDataState && (
                              <span
                                className='text-dark font-weight-normal'
                                style={{fontSize: '14px'}}
                              >
                                {billingAddressDataState.address_line_one
                                  ? ` ${billingAddressDataState.address_line_one},`
                                  : ''}
                                {billingAddressDataState.address_line_two
                                  ? ` ${billingAddressDataState.address_line_two},`
                                  : ''}
                                <br />
                                {billingAddressDataState.city
                                  ? ` ${billingAddressDataState.city},`
                                  : ''}
                                {billingAddressDataState.state
                                  ? ` ${billingAddressDataState.state},`
                                  : ''}
                                <br />
                                {billingAddressDataState.country
                                  ? ` ${billingAddressDataState.country} -`
                                  : ''}
                                {billingAddressDataState.zip_code
                                  ? ` ${billingAddressDataState.zip_code}`
                                  : ''}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12 mb-4 pr-5'>
                          <h3 className='mb-2'>{'Shipping Address'}</h3>
                          <span>
                            {shipmentAddressDataState && (
                              <div
                                className='text-dark font-weight-normal'
                                style={{fontSize: '14px'}}
                              >
                                {shipmentAddressDataState.address_line_one
                                  ? ` ${shipmentAddressDataState.address_line_one},`
                                  : ''}
                                {shipmentAddressDataState.address_line_two
                                  ? ` ${shipmentAddressDataState.address_line_two},`
                                  : ''}
                                {/* {shipmentAddressDataState.street
                              ? ` ${shipmentAddressDataState.street},`
                              : ''} */}
                                <br />
                                {shipmentAddressDataState.city
                                  ? ` ${shipmentAddressDataState.city},`
                                  : ''}
                                {shipmentAddressDataState.state
                                  ? ` ${shipmentAddressDataState.state},`
                                  : ''}
                                <br />
                                {shipmentAddressDataState.country
                                  ? ` ${shipmentAddressDataState.country} -`
                                  : ''}
                                {shipmentAddressDataState.zip_code
                                  ? ` ${shipmentAddressDataState.zip_code}`
                                  : ''}
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className='row border-bottom pt-4'
                        style={{borderTop: '1px solid #eff2f5'}}
                      >
                        <h3 className='mb-10'>{'Stock Details'}</h3>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          {'Stock Id'}
                        </div>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          {'Vendor'}
                        </div>
                        <div className='col-lg-4 text-dark font-weight-bold text-muted'>
                          {'Details'}
                        </div>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'Discount'}</div>
                        </div>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'$/Ct'}</div>
                        </div>
                        {/* <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'Price'}</div>
                        </div> */}
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'Action Taken'}</div>
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                          <div className='text-center'>{'Availability'}</div>
                        </div>
                        <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                          <div className='text-right'>{'Price'}</div>
                        </div>
                      </div>
                      <div style={{maxHeight: '100vh', overflowX: 'hidden', overflowY: 'auto'}}>
                        {updatedStockData.map(
                          (invItem: any, index: number) =>
                            invItem && (
                              <div className='row mt-10' key={index}>
                                <div className='col-lg-1 text-dark font-weight-bold'>
                                  <label>
                                    {/* <a
                                      href={`/inventory/preview/${invItem.stock_id}`}
                                      target='_blank'
                                    > */}
                                    {/* {returnStockId(invItem.stock_id)} */}
                                    {invItem.stock && invItem.stock.stock_id
                                      ? invItem.stock.stock_id
                                      : '-'}
                                    {/* </a> */}
                                  </label>
                                </div>
                                <div className='col-lg-1 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.vendor
                                      ? invItem.vendor?.first_name && invItem.vendor?.last_name
                                        ? `${invItem.vendor?.first_name} ${invItem.vendor?.last_name}`
                                        : invItem.vendor?.first_name
                                        ? invItem.vendor?.first_name
                                        : '-'
                                      : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-4 text-dark font-weight-bold'>
                                  <label>{returnStockString(invItem.stock)}</label>
                                </div>
                                <div className='col-lg-1 text-dark font-weight-bold'>
                                  <div className='text-right'>
                                    <label>{invItem.stock.discounts}</label>
                                  </div>
                                </div>
                                <div className='col-lg-1 text-dark font-weight-bold'>
                                  <div className='text-right'>
                                    <label>{invItem.stock.price_per_caret}</label>
                                  </div>
                                </div>
                                {/* <div className='col-lg-1 text-dark font-weight-bold'>
                                  <div className='text-right'>
                                    <label>
                                      {returnPriceInThousandFormatWithCurrencySign(
                                        invItem.stock.final_price
                                      )}
                                    </label>
                                  </div>
                                </div> */}
                                <div className='col-lg-1 text-dark font-weight-bold'>
                                  <div className='text-right'>
                                    <label>
                                      {invItem && invItem.is_action_taken ? (
                                        <Tag color='success'>Yes</Tag>
                                      ) : (
                                        <Tag color='red'>No</Tag>
                                      )}
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className='col-lg-2 text-dark font-weight-bold'
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {/* <InputSingleSelect
                                    input={{
                                      value: invItem.is_available,
                                      id: 'status',
                                      name: 'status',
                                      options: this.stockAvailableStatus,
                                    }}
                                    placeholder='Stock Status'
                                    onChangeMethod={(value) => {
                                      this.onChangeStockUpdate(invItem.stock_id, value)
                                    }}
                                    disabled={invItem.vendor_id ? true : false }
                                    // label='Stock Status'
                                    error={errors}
                                    touched={touched}
                                    clearable={false}
                                    menuPosition='fixed'
                                    required={false}
                                  /> */}
                                  <Switch
                                    defaultChecked={invItem.is_available}
                                    checked={invItem.is_available}
                                    style={{
                                      backgroundColor: invItem.is_available
                                        ? PRIMARY_COLOR
                                        : '#bababa',
                                    }}
                                    disabled={invItem.vendor_id ? true : false}
                                    onChange={(value: any) => {
                                      this.onChangeStockUpdate(invItem.stock_id, value)
                                    }}
                                  />
                                </div>
                                <div className='col-lg-1 text-dark font-weight-bold'>
                                  <div className='text-right'>
                                    <label>
                                      {returnPriceInThousandFormatWithCurrencySign(
                                        invItem.stock.final_price
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                      <div className='row border-top pt-6'>
                        <div className='col-lg-10 text-dark font-weight-bold text-end'>
                          {'Sub Total:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          <div className='text-right'>{this.calculateTotal()}</div>
                        </div>
                      </div>
                      {/* <div className='row mt-5'>
                        <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                          {'Discount:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          <div className='text-right'>{`${this.calculateAvgDiscount()}(%)`}</div>
                        </div>
                      </div> */}
                      <div className='row mt-5'>
                        <div className='col-lg-10 text-dark font-weight-bold text-end'>
                          {'Shipment Price:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          <div className='text-right'>{this.calculateShipMent()}</div>
                        </div>
                      </div>
                      <div className='row mt-5'>
                        <div className='col-lg-10 text-dark font-weight-bold text-end'>
                          {'Tax Price:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          <div className='text-right'>
                            {apiResonseData && apiResonseData.tax_price
                              ? returnPriceInThousandFormatWithCurrencySign(
                                  apiResonseData.tax_price
                                )
                              : '0'}
                          </div>
                        </div>
                      </div>
                      <div className='row border-bottom mt-5 pb-6'>
                        <div className='col-lg-10 text-dark font-weight-bold text-end'>
                          {'Total:'}
                        </div>
                        <div className='col-lg-2 text-dark font-weight-bold'>
                          {/* <div className='text-right'>{this.calculateGrandTotal()}</div> */}
                          <div className='text-right'>{apiResonseData && apiResonseData.grand_total
                              ? returnPriceInThousandFormatWithCurrencySign(
                                  apiResonseData.grand_total
                                )
                              : '0'}</div>
                        </div>
                      </div>
                    </>
                  </div>
                  {/* <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 mb-10 ml-2 text-muted'>
                      <h3 className='mb-2'>{'Billing Address'}</h3>
                      <span>
                        {billingAddressDataState && (
                          <span
                            className='text-dark font-weight-normal text-muted'
                            style={{fontSize: '14px'}}
                          >
                            {billingAddressDataState.address_line_one
                              ? ` ${billingAddressDataState.address_line_one},`
                              : ''}
                            {billingAddressDataState.address_line_two
                              ? ` ${billingAddressDataState.address_line_two},`
                              : ''}
                            {billingAddressDataState.city
                              ? ` ${billingAddressDataState.city},`
                              : ''}
                            {billingAddressDataState.state
                              ? ` ${billingAddressDataState.state},`
                              : ''}
                            {billingAddressDataState.country
                              ? ` ${billingAddressDataState.country} -`
                              : ''}
                            {billingAddressDataState.zip_code
                              ? ` ${billingAddressDataState.zip_code}`
                              : ''}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-12 mb-10'>
                      <h3 className='mb-2'>{'Shipping Address'}</h3>
                      <span>
                        {shipmentAddressDataState && (
                          <div
                            className='text-dark font-weight-normal text-muted'
                            style={{fontSize: '14px'}}
                          >
                            {shipmentAddressDataState.address_line_one
                              ? ` ${shipmentAddressDataState.address_line_one},`
                              : ''}
                            {shipmentAddressDataState.address_line_two
                              ? ` ${shipmentAddressDataState.address_line_two},`
                              : ''}
                            {shipmentAddressDataState.city
                              ? ` ${shipmentAddressDataState.city},`
                              : ''}
                            {shipmentAddressDataState.state
                              ? ` ${shipmentAddressDataState.state},`
                              : ''}
                            {shipmentAddressDataState.country
                              ? ` ${shipmentAddressDataState.country} -`
                              : ''}
                            {shipmentAddressDataState.zip_code
                              ? ` ${shipmentAddressDataState.zip_code}`
                              : ''}
                          </div>
                        )}
                      </span>
                    </div>
                  </div> */}
                </div>
              </Spin>
            )
          }}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUserData: state.auth.user,
})

const mapDispatchToProps = (dispatch: any) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrintBuyRequestPreviewComponent))
