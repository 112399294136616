/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as declinestock from '../redux/DeclineStockRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {
  getDeclineStockData,
} from '../redux/DeclineStockCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import moment from 'moment'
import {returnPriceInThousandFormatWithCurrencySign, returnStockId} from '../../../comman/helper'

const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  declineStockData: any
  declineStockDataTotal: number
  declineStockLoading: boolean
  setListDeclineStockData: (declineStockData: any) => void
  setListDeclineStockDataTotal: (declineStockDataTotal: number) => void
  setDeclineStockLoading: (declineStockLoading: boolean) => void
  history?: History<LocationState>
  loginUser?: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  stockSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListDeclineStockData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      stockSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListDeclineStockData(pagination)
    )
  }

  public getListDeclineStockData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listDeclineStockSearchData(start, end)
        } else {
          this.listDeclineStockData(start, end)
        }
      }
    )
  }

  public listDeclineStockData = async (start: number, end: any) => {
    const {setDeclineStockLoading} = this.props
    try {
      const {setListDeclineStockData, setListDeclineStockDataTotal} = this.props
      await setDeclineStockLoading(true)
      let declineStockData: any = await getDeclineStockData(start, end)
      if (declineStockData.data) {
        await setListDeclineStockData(declineStockData.data)
        await setListDeclineStockDataTotal(declineStockData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setDeclineStockLoading(false)
    }
  }

  public listDeclineStockSearchData = async (start: number, end: any) => {
    const {setDeclineStockLoading} = this.props
    try {
      const {setListDeclineStockData, setListDeclineStockDataTotal} = this.props
      const {searchText} = this.state

      await setDeclineStockLoading(true)
      let declineStockData: any = await getDeclineStockData(start, end)
      if (declineStockData.data) {
        await setListDeclineStockData(declineStockData.data)
        await setListDeclineStockDataTotal(declineStockData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setDeclineStockLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listDeclineStockSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      width: 80,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Vendor Name',
      key: 'first_name',
      fixed: window.innerWidth < 768 ? false : true,
      sorter: (a: any, b: any) => {
        const fullNameA = `${a.vendor && a.vendor.first_name || ''} ${
          a.vendor && a.vendor.last_name || ''
        }`.trim()
        const fullNameB = `${b.vendor && b.vendor.first_name || ''} ${
          b.vendor && b.vendor.last_name || ''
        }`.trim()
        return fullNameA.localeCompare(fullNameB)
      },
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.vendor && item.vendor.first_name && item.vendor.last_name
                ? `${item.vendor.first_name} ${item.vendor.last_name}`
                : item.vendor && item.vendor.first_name
                ? item.vendor.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.vendor ? (item.vendor.email ? item.vendor.email : '-') : '-'}
          </div>
        )
      },
    },
    {
      title: 'Reason',
      key: 'reject_reason',
      render: (text: any, item: any, index: number) => {
        return (
          <Tooltip placement='bottom' title={item && item.reject_reason ? item.reject_reason : '-'}>
            <div className='d-inline-block text-truncate' style={{maxWidth: '150px'}}>
              {' '}
              {item && item.reject_reason ? item.reject_reason : '-'}
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: 'Update At',
      key: 'updatedAt',
      sorter: (a: any, b: any) => {
        const dateA: any = new Date(a.updatedAt)
        const dateB: any = new Date(b.updatedAt)
        return dateA - dateB
      },
      render: (text: any, item: any, index: number) => {
        return <div>{item.updatedAt ? moment(item.updatedAt).format('DD-MM-YYYY') : '-'}</div>
      },
    },
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'Stock Id',
      width: 120,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a href={`/inventory/preview/${item.stock.stock_id ? item.stock.stock_id : ''}`} target='_blank'>
              <strong>
                {item.stock && item.stock.stock_id ? returnStockId(item.stock.stock_id) : '-'}
              </strong>
            </a>
          </div>
        )
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.shape ? returnStockId(item.stock.shape) : '-'}</div>
      },
    },
    // {
    //   // title: 'Weight',
    //   title: 'WEIGHT',
    //   key: 'weight',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.weight ? item.stock.weight : '-'}</div>
    //   },
    // },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.color ? returnStockId(item.stock.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : '-'}</div>
        )
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.cut ? returnStockId(item.stock.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.polish ? returnStockId(item.stock.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : '-'}</div>
        )
      },
    },
    {
      // title: 'Discounts',
      title: 'DISCOUNTS',
      // ellipsis: true,
      key: 'discounts',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.discounts ? item.stock.discounts : '-'}</div>
      },
    },
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.price_per_caret ? item.stock.price_per_caret : '-'}</div>
        )
      },
    },
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.stock && item.stock.final_price
              ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price)
              : '-'}
          </div>
        )
      },
    },
  ]

  public render() {
    const {declineStockData, declineStockDataTotal, declineStockLoading, loginUser} = this.props
    const {searchText, stockSelectedData} = this.state

    return (
          <div className='card card-custom'>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  column={this.columns}
                  paginationConfig={{
                    total: declineStockDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={declineStockLoading}
                  dataSource={declineStockData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={1400}
                />
              </div>
            </div>
          </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  declineStockData: state.declinestock.declineStockData,
  declineStockDataTotal: state.declinestock.declineStockDataTotal,
  declineStockLoading: state.declinestock.declineStockLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListDeclineStockData: (declineStockData: any) => {
      dispatch(declinestock.actions.setDeclineStockData(declineStockData))
    },
    setListDeclineStockDataTotal: (declineStockDataTotal: number) => {
      dispatch(declinestock.actions.setDeclineStockDataTotal(declineStockDataTotal))
    },
    setDeclineStockLoading: (declineStockLoading: boolean) => {
      dispatch(declinestock.actions.setDeclineStockLoading(declineStockLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListDeclineStockData))
