import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setBannerData: 'SET_BANNER_DATA',
  setBannerDataTotal: 'SET_BANNER_DATA_TOTAL',
  setBannerLoading: 'SET_BANNER_LOADER',
}

const initialOrderState: IOrderState = {
  bannerData: undefined,
  bannerDataTotal: undefined,
  bannerLoading: false,
}

export interface IOrderState {
  bannerData?: any
  bannerDataTotal?: number
  bannerLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'banner', whitelist: ['bannerData', 'bannerDataTotal']},
  (state: IOrderState = initialOrderState, action: ActionWithPayload<IOrderState>) => {
    switch (action.type) {
      case actionTypes.setBannerData: {
        const bannerData = action.payload?.bannerData
        return {...state, bannerData}
      }

      case actionTypes.setBannerDataTotal: {
        const bannerDataTotal = action.payload?.bannerDataTotal
        return {...state, bannerDataTotal}
      }

      case actionTypes.setBannerLoading: {
        const bannerLoading = action.payload?.bannerLoading
        return {...state, bannerLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setBannerData: (bannerData: Array<any>) => ({
    type: actionTypes.setBannerData,
    payload: {bannerData: bannerData},
  }),
  setBannerDataTotal: (bannerDataTotal: number) => ({
    type: actionTypes.setBannerDataTotal,
    payload: {bannerDataTotal},
  }),
  setBannerLoading: (bannerLoading: boolean) => ({
    type: actionTypes.setBannerLoading,
    payload: {bannerLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setBannerData, function* loginSaga() {
    yield put(actions.setBannerLoading(false))
  })
}
