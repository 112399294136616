/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as sales from '../redux/SalesReportRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {getSalesReportData} from '../redux/SalesReportCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import moment from 'moment'
import {returnPriceInThousandFormatWithCurrencySign, returnStockId} from '../../../comman/helper'
import {Tag} from 'antd'
import {KpiCardComponent} from '../../../modules/comman/components/KpiCardComponent'

const format = 'YYYY-MM-DD'
const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  salesReportData: any
  salesReportDataTotal: number
  salesReportLoading: boolean
  salesReportStartDate: any
  salesReportEndDate: any
  totalSalesData: any
  setListSalesReportData: (salesReportData: any) => void
  setListSalesReportDataTotal: (salesReportDataTotal: number) => void
  setSalesReportLoading: (salesReportLoading: boolean) => void
  setSalesReportStartDate: (startDate: any) => void
  setSalesReportEndDate: (endDate: any) => void
  setTotalSalesData: (totalSalesData: any) => void
  history?: History<LocationState>
  loginUser?: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  salesSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListSalesReportData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      salesSelectedData: null,
    }
  }

  public navigateToPreviewOrderPage = async (item: any) => {
    const {history} = this.props
    history.push(`${process.env.PUBLIC_URL}/sales-report/preview/` + item.id)
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListSalesData(pagination)
    )
  }

  public getListSalesData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listSalesData(start, end)
      }
    )
  }

  public listSalesData = async (start: number, end: any) => {
    const {setSalesReportLoading} = this.props
    try {
      const {
        setListSalesReportData,
        setListSalesReportDataTotal,
        salesReportStartDate,
        salesReportEndDate,
        setTotalSalesData,
      } = this.props
      await setSalesReportLoading(true)
      let salesReportData: any = await getSalesReportData(
        start,
        end,
        salesReportStartDate,
        salesReportEndDate
      )
      if (salesReportData.data) {
        await setListSalesReportData(salesReportData.data)
        await setTotalSalesData(salesReportData.total_selling)
        await setListSalesReportDataTotal(salesReportData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setSalesReportLoading(false)
    }
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Order Code',
      key: 'order_code',
      width: 130,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.order_code ? returnStockId(item.order_code) : '-'}</strong>
          </div>
        )
      },
    },
    // {
    //   // title: 'Stock Id',
    //   title: 'STOCK ID',
    //   key: 'Stock Id',
    //   width: 80,
    //   // fixed: window.innerWidth < 768 ? false : true,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //           {item.stock && item.stock.stock_id ? returnStockId(item.stock.stock_id) : '-'}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: 'Status',
    //   key: 'order_status',
    //   fixed: window.innerWidth < 768 ? false : true,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.order_status ? (
    //           <Tag
    //             color={
    //               config.statusTagColor[item.order_status]
    //                 ? config.statusTagColor[item.order_status]
    //                 : 'default'
    //             }
    //           >
    //             {item.order_status}
    //           </Tag>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Full Name',
      key: 'Full Name',
      fixed: window.innerWidth < 768 ? false : true,
      sorter: (a: any, b: any) => {
        const fullNameA = `${a.user_details?.first_name || ''} ${
          a.user_details?.last_name || ''
        }`.trim()
        const fullNameB = `${b.user_details?.first_name || ''} ${
          b.user_details?.last_name || ''
        }`.trim()
        return fullNameA.localeCompare(fullNameB)
      },
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.user_details?.first_name && item?.user_details?.last_name
                ? `${item.user_details.first_name} ${item.user_details.last_name}`
                : item?.user_details?.first_name
                ? item?.user_details?.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: 230,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item?.user_details ? (item?.user_details?.email ? item.user_details.email : '-') : '-'}
          </div>
        )
      },
    },
    {
      title: 'Type',
      key: 'type',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item && item.type ? (
              <Tag color={item.type == 'OFFER' ? 'green' : 'blue'}>{item.type}</Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Order Date',
      key: 'createdAt',
      sorter: (a: any, b: any) => {
        const dateA: any = new Date(a.createdAt)
        const dateB: any = new Date(b.createdAt)
        return dateA - dateB
      },
      render: (text: any, item: any, index: number) => {
        return <div>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : '-'}</div>
      },
    },
    {
      title: 'Invoice',
      key: 'invoice_number',
      width: 150,
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{fontWeight: 500}}>
            {item.invoice_number && item.invoice_url ? (
              <a target='blank' href={item.invoice_url}>
                {item.invoice_number ? item.invoice_number : '-'}
              </a>
            ) : item.invoice_number ? (
              <div>{item.invoice_number ? item.invoice_number : '-'}</div>
            ) : (
              <Tag color='red'>No Invoice</Tag>
            )}
          </div>
        )
      },
    },
    {
      title: 'Shipment Price',
      key: 'shipment_price',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.shipment_price
              ? returnPriceInThousandFormatWithCurrencySign(item.shipment_price.toFixed(2))
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Total Amount',
      key: 'amount',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.amount
              ? returnPriceInThousandFormatWithCurrencySign(item.amount.toFixed(2))
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Tax Amount',
      key: 'tax_price',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.tax_price
              ? returnPriceInThousandFormatWithCurrencySign(item.tax_price.toFixed(2))
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Grand Amount',
      key: 'grand_total',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item && item.grand_total
              ? returnPriceInThousandFormatWithCurrencySign(item.grand_total.toFixed(2))
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Preview'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    // this.showPreviewModal(item)
                    this.navigateToPreviewOrderPage(item)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {salesReportData, salesReportDataTotal, salesReportLoading, loginUser, totalSalesData} =
      this.props
    const {searchText, salesSelectedData} = this.state
    return (
      <div>
        <div>
          {/* <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='TOTAL SALES'
            value={totalSalesData ? totalSalesData : ''}
            background='white'
          /> */}
          <div
            style={{width: '285px'}}
            className='bg-white pl-4 pr-4 pt-2 pb-2 mb-4 rounded-lg shadow-md flex justify-between'
          >
            <div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: '36px',
                  fontFamily: 'Arial, Helvetica, sans-serif',
                }}
              >
                Total Sales
              </div>
              <div
                className='text-muted'
                style={{
                  fontWeight: 400,
                  fontSize: '18px',
                  fontFamily: 'Arial, Helvetica, sans-serif',
                }}
              >
                $ {totalSalesData ? totalSalesData : 0}
              </div>
            </div>
          </div>
          <div className='card card-custom'>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  column={this.columns}
                  paginationConfig={{
                    total: salesReportDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={salesReportLoading}
                  dataSource={salesReportData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={1625}
                />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  salesReportData: state.sales.salesReportData,
  salesReportDataTotal: state.sales.salesReportDataTotal,
  salesReportLoading: state.sales.salesReportLoading,
  salesReportStartDate: state.sales.startDate,
  salesReportEndDate: state.sales.endDate,
  totalSalesData: state.sales.totalSalesData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListSalesReportData: (salesReportData: any) => {
      dispatch(sales.actions.setSalesReportData(salesReportData))
    },
    setListSalesReportDataTotal: (salesReportDataTotal: number) => {
      dispatch(sales.actions.setSalesReportDataTotal(salesReportDataTotal))
    },
    setSalesReportLoading: (salesReportLoading: boolean) => {
      dispatch(sales.actions.setSalesReportLoading(salesReportLoading))
    },
    setSalesReportStartDate: (startDate: any) => {
      dispatch(sales.actions.setStartDate(startDate))
    },
    setSalesReportEndDate: (endDate: any) => {
      dispatch(sales.actions.setStartDate(endDate))
    },
    setTotalSalesData: (totalSalesData: any) => {
      dispatch(sales.actions.setTotalSalesData(totalSalesData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListSalesReportData))
