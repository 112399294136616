/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {
  getInventoryData,
  getSearchInventoryData,
  getSingleInventoryData,
} from '../redux/InventoryCRUD'
import {useDispatch} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import ListInventoryData from './ListInventoryData'
import ListSearchForm from './ListSearchForm'
import ViewInventoryForm from './ViewInventoryForm'
import {RouteComponentProps} from 'react-router'
import {shallowEqual, useSelector} from 'react-redux'
import { RootState } from '../../../../setup'

interface MyParams {
  id: string
}

const PreviewPage: React.FC<any> = (props) => {
  const dispatch = useDispatch()
  const isAuthorized: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const {inventoryId} = props

  console.log(isAuthorized,"isAuth")

  const callInventoryData = async () => {
    try {
      // console.log(props)
      let inventoryData = await getSingleInventoryData(inventoryId,isAuthorized.id,isAuthorized.role)
      if (inventoryData.data) {
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setSingleInventoryData(inventoryData.data))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInventoryData()
  }, [])

  return (
    <div>
      <ViewInventoryForm />
    </div>
  )
}

const PreviewWrapper: React.FC<RouteComponentProps<MyParams>> = (props) => {
  const intl = useIntl()

  const inventoryId = props.match.params.id

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'View',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'View',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <PreviewPage inventoryId={inventoryId} />
    </div>
  )
}

export {PreviewWrapper}
