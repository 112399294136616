import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {ClientWrapper} from '../pages/client/ClientWrapper'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {InventoryWrapper} from '../pages/inventory/InventoryWrapper'
import {UsersWrapper} from '../pages/users/UsersWrapper'
import {VendorsWrapper} from '../pages/vendors/VendorsWrapper'
import {SubAdminWrapper} from '../pages/subadmin/SubAdminWrapper'
import {SettingWrapper} from '../pages/setting/SettingWrapper'
import {BuyRequestWrapper} from '../pages/buyRequest/BuyRequestWrapper'
import {VendorStockWrapper} from '../pages/vendorStock/VendorStockWrapper'
import {BannerWrapper} from '../pages/banner/BannerWrapper'
import {OrderWrapper} from '../pages/order/OrderWrapper'
import {VendorOrderWrapper} from '../pages/vendororder/VendorOrderWrapper'
import {KycWrapper} from '../pages/kyc/KycWrapper'
import {ChatsWrapper} from '../pages/chats/ChatsWrapper'
import {ReturnOrderWrapper} from '../pages/returnorder/ReturnOrderWrapper'
import {ClientOfferWrapper} from '../pages/clientoffer/ClientOfferWrapper'
import {StaticPageWrapper} from '../pages/staticpage/StaticPageWrapper'
import {SalesReportWrapper} from '../pages/sales/SalesReportWrapper'
import {DeclineStockWrapper} from '../pages/declinestock/DeclineStockWrapper'

export function PrivateRoutes(props: any) {
  const {role, permission} = props?.adminData

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* For Dashboard */}
        <Route path='/dashboard' exact component={DashboardWrapper} />

        {/* For Setting */}
        <Route path='/setting' component={SettingWrapper} />
        {(role !== 'sub_admin' || (role === 'sub_admin' && permission && permission.vendororder)) && (
          <Route path='/vendor-order' component={VendorOrderWrapper} />
        )}

        {/* For Sun Admin */}
        {role === 'super_admin' && <Route path='/subadmin' component={SubAdminWrapper} />}

        {/* For Users */}
        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.user)) && (
          <Route path='/users' component={UsersWrapper} />
        )}

        {/* For Vendor */}
        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.vendors)) && (
          <Route path='/vendors' component={VendorsWrapper} />
        )}
        {/* For Inventory */}
        {(role === 'super_admin' ||
          (role === 'sub_admin' && permission && permission.list_inventory) ||
          role === 'vendor') && <Route path='/inventory' component={InventoryWrapper} />}

        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.buyrequest)) && (
          <Route path='/buy-request' component={BuyRequestWrapper} />
        )}

        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.banner)) && (
          <Route path='/banner' component={BannerWrapper} />
        )}

        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.order)) && (
          <Route path='/order' component={OrderWrapper} />
        )}

        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.kyc)) && (
          <Route path='/kyc' component={KycWrapper} />
        )}

        {role === 'vendor' && <Route path='/vendor-stock' component={VendorStockWrapper} />}

        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.chats)) && ( <Route path='/chats' component={ChatsWrapper} />   )}
        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.sales)) && ( <Route path='/sales-report' component={SalesReportWrapper} />   )}
        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.declinestocks)) && ( <Route path='/decline-stock' component={DeclineStockWrapper} />   )}

        {(role === 'super_admin' ||
          (role === 'sub_admin' && permission && permission.return_order) ||
          role === 'vendor') && <Route path='/returnorder' component={ReturnOrderWrapper} />}
        {(role === 'super_admin' ||
          (role === 'sub_admin' && permission && permission.stock_offer) ||
          role === 'vendor') && <Route path='/clientoffer' component={ClientOfferWrapper} />}

        {(role === 'super_admin' || (role === 'sub_admin' && permission && permission.static_page)) && (
          <Route path='/static' component={StaticPageWrapper} />
        )}

        {/* <Route path='/buy-request' component={BuyRequestWrapper} /> */}

        {/* For Cart */}
        {/* {(role === 'super_admin' || role === 'sub_admin') && (
          <Route path='/banner' component={BannerWrapper} />
        )}
        {(role === 'super_admin' || role === 'sub_admin') && (
          <Route path='/order' component={BannerWrapper} />
        )} */}

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
