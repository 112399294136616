import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {Switch} from 'antd'
import {config} from '../../../comman/constants'

const PRIMARY_COLOR = config.buttonPrimaryColor

interface IRenderFormikForm {
  values: IUserFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IUserFormValues {
  first_name: string
  last_name: string
  email: string
  permission: {
    user: any
    vendors: any
    list_inventory: any
    banner: any
    buyrequest: any
    order: any
    vendororder: any
    kyc: any
    stock_offer: any
    return_order: any
    chats: any
    sales: any
    declinestocks: any
    static_page: any
  }
}

interface IEditUserProps {
  onSubmit: any
  data: any
  handleCancel?: any
}

export class EditSubAdminForm extends React.Component<IEditUserProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IUserFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.first_name) {
      errors.first_name = 'First name is required'
    }

    if (!values.last_name) {
      errors.last_name = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    }

    if (
      values.email &&
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(values.email).toLowerCase()
      )
    ) {
      errors.email = 'Please Provide Valid Email'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: IUserFormValues, action: any) => {
    const {data, handleCancel, onSubmit} = this.props
    console.log('Basic Value - ', values)
    // values.permission.user = values.permission.user == 'true' ? true : false
    // values.permission.vendors = values.permission.vendors == 'true' ? true : false
    // values.permission.list_inventory = values.permission.list_inventory == 'true' ? true : false

    // values.phone_iso = values.phone.split('-').length > 0 ? values.phone.split('-')[0] : ''
    onSubmit(values, data?.id)
    handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data} = this.props

    return (
      <div className='container'>
        {console.log(data, 'data')}
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  first_name: data.first_name ? data.first_name : '',
                  last_name: data.last_name ? data.last_name : '',
                  email: data.email ? data.email : '',
                  id: data.id ? data.id : '',
                  permission: {
                    user: data.permission && data.permission.user ? true : false,
                    vendors: data.permission && data.permission.vendors ? true : false,
                    list_inventory:
                      data.permission && data.permission.list_inventory ? true : false,
                    banner: data.permission && data.permission.banner ? true : false,
                    order: data.permission && data.permission.order ? true : false,
                    vendororder: data.permission && data.permission.vendororder ? true : false,
                    buyrequest: data.permission && data.permission.buyrequest ? true : false,
                    kyc: data.permission && data.permission.kyc ? true : false,
                    stock_offer: data.permission && data.permission.stock_offer ? true : false,
                    return_order: data.permission && data.permission.return_order ? true : false,
                    chats: data.permission && data.permission.chats ? true : false,
                    sales: data.permission && data.permission.sales ? true : false,
                    declinestocks: data.permission && data.permission.declinestocks ? true : false,
                    static_page: data.permission && data.permission.static_page ? true : false,
                  },
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.first_name,
                              id: 'first_name',
                              name: 'first_name',
                            }}
                            placeholder='Enter first name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('first_name', value)
                            }}
                            onBlur={handleBlur}
                            label='First Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.last_name,
                              id: 'last_name',
                              name: 'last_name',
                            }}
                            placeholder='Enter last name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('last_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Last Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.email,
                              id: 'email',
                              name: 'email',
                            }}
                            placeholder='Enter email'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('email', value)
                            }}
                            onBlur={handleBlur}
                            label='Email'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputSingleSelect
                            input={{
                              //   value: values.user,
                              id: 'user',
                              name: 'user',
                              value: values.permission.user ? values.permission.user : undefined,
                              options: [
                                {
                                  label: 'Yes',
                                  value: 'true',
                                },
                                {
                                  label: 'No',
                                  value: 'false',
                                },
                              ],
                            }}
                            placeholder='User Permission'
                            onChangeMethod={(value: string) => {
                              setFieldValue('permission.user', value)
                            }}
                            label='User Permission'
                            error={errors}
                            touched={touched}
                          />
                        </div> */}
                      </div>
                      {/* <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputSingleSelect
                              input={{
                                //   value: values.vendors,
                                id: 'vendors',
                                name: 'vendors',
                              value: values.permission.vendors ? values.permission.vendors : undefined,
                                options: [
                                  {
                                    label: 'Yes',
                                    value: 'true',
                                  },
                                  {
                                    label: 'No',
                                    value: 'false',
                                  },
                                ],
                              }}
                              placeholder='Vendor Permission'
                              onChangeMethod={(value: string) => {
                                setFieldValue('permission.vendors', value)
                              }}
                              label='Vendor Permission'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputSingleSelect
                              input={{
                                //   value: values.list_inventory,
                                id: 'list_inventory',
                                name: 'list_inventory',
                              value: values.permission.list_inventory ? values.permission.list_inventory : undefined,
                                options: [
                                  {
                                    label: 'Yes',
                                    value: 'true',
                                  },
                                  {
                                    label: 'No',
                                    value: 'false',
                                  },
                                ],
                              }}
                              placeholder='Inventory Permission'
                              onChangeMethod={(value: string) => {
                                setFieldValue('permission.list_inventory', value)
                              }}
                              label='Inventory Permission'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                      </div> */}

                      <div className='card-header' style={{padding: 0, marginBottom: '20px'}}>
                        <h3 className='card-title'>Permissions</h3>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          {/* <InputSingleSelect
                            input={{
                              //   value: values.user,
                              id: 'user',
                              name: 'user',
                              options: [
                                {
                                  label: 'Yes',
                                  value: 'true',
                                },
                                {
                                  label: 'No',
                                  value: 'false',
                                },
                              ],
                            }}
                            placeholder='User Permission'
                            onChangeMethod={(value: string) => {
                              setFieldValue('permission.user', value)
                            }}
                            label='User Permission'
                            error={errors}
                            touched={touched}
                          /> */}
                          <span style={{fontSize: '14px', fontWeight: 500}}>User</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.user}
                            style={{
                              backgroundColor: values.permission.user ? PRIMARY_COLOR : '#bababa',
                              marginLeft: '15px',
                            }}
                            className='align-self-end'
                            onChange={(value: any) => {
                              setFieldValue('permission.user', value)
                            }}
                          />
                        </div>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Vendor</span>
                          {/* <InputSingleSelect
                            input={{
                              //   value: values.vendors,
                              id: 'vendors',
                              name: 'vendors',
                              options: [
                                {
                                  label: 'Yes',
                                  value: 'true',
                                },
                                {
                                  label: 'No',
                                  value: 'false',
                                },
                              ],
                            }}
                            placeholder='Vendor Permission'
                            onChangeMethod={(value: string) => {
                              setFieldValue('permission.vendors', value)
                            }}
                            label='Vendor Permission'
                            error={errors}
                            touched={touched}
                          /> */}
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.vendors}
                            style={{
                              backgroundColor: values.permission.vendors
                                ? PRIMARY_COLOR
                                : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.vendors', value)
                            }}
                          />
                        </div>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          {/* <InputSingleSelect
                              input={{
                                //   value: values.list_inventory,
                                id: 'list_inventory',
                                name: 'list_inventory',
                                options: [
                                  {
                                    label: 'Yes',
                                    value: 'true',
                                  },
                                  {
                                    label: 'No',
                                    value: 'false',
                                  },
                                ],
                              }}
                              placeholder='Inventory Permission'
                              onChangeMethod={(value: string) => {
                                setFieldValue('permission.list_inventory', value)
                              }}
                              label='Inventory Permission'
                              error={errors}
                              touched={touched}
                            /> */}
                          <span style={{fontSize: '14px', fontWeight: 500}}>Inventory</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.list_inventory}
                            style={{
                              backgroundColor: values.permission.list_inventory
                                ? PRIMARY_COLOR
                                : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.list_inventory', value)
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Buy Request</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.buyrequest}
                            style={{
                              backgroundColor: values.permission.buyrequest
                                ? PRIMARY_COLOR
                                : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.buyrequest', value)
                            }}
                          />
                        </div>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Order</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.order}
                            style={{
                              backgroundColor: values.permission.order ? PRIMARY_COLOR : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.order', value)
                            }}
                          />
                        </div>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Vendor Order</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.vendororder}
                            style={{
                              backgroundColor: values.permission.vendororder
                                ? PRIMARY_COLOR
                                : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.vendororder', value)
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Banner</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.banner}
                            style={{
                              backgroundColor: values.permission.banner ? PRIMARY_COLOR : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.banner', value)
                            }}
                          />
                        </div>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>KYC</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.kyc}
                            style={{
                              backgroundColor: values.permission.kyc ? PRIMARY_COLOR : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.kyc', value)
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Return Order</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.return_order}
                            style={{
                              backgroundColor: values.permission.return_order
                                ? PRIMARY_COLOR
                                : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.return_order', value)
                            }}
                          />
                        </div>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Stock Offer</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.stock_offer}
                            style={{
                              backgroundColor: values.permission.stock_offer
                                ? PRIMARY_COLOR
                                : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.stock_offer', value)
                            }}
                          />
                        </div>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Chats</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.chats}
                            style={{
                              backgroundColor: values.permission.chats ? PRIMARY_COLOR : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.chats', value)
                            }}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Sales Report</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.sales}
                            style={{
                              backgroundColor: values.permission.sales ? PRIMARY_COLOR : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.sales', value)
                            }}
                          />
                        </div>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>
                            Vendor Decline Stock
                          </span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.declinestocks}
                            style={{
                              backgroundColor: values.permission.declinestocks
                                ? PRIMARY_COLOR
                                : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.declinestocks', value)
                            }}
                          />
                        </div>
                        <div className='col-md-4 col-lg-4 col-sm-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>
                            Static Pages
                          </span>
                          <Switch
                            defaultChecked={false}
                            checked={values.permission.static_page}
                            style={{
                              backgroundColor: values.permission.static_page
                                ? PRIMARY_COLOR
                                : '#bababa',
                              marginLeft: '15px',
                            }}
                            onChange={(value: any) => {
                              setFieldValue('permission.static_page', value)
                            }}
                          />
                        </div>
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Save</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
