/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as clientoffer from '../redux/ClientOfferRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {getClientOfferData, clientOfferActionApi} from '../redux/ClientOfferCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import moment from 'moment'
import {Tag} from 'antd'
import {returnPriceInThousandFormatWithCurrencySign, returnStockId} from '../../../comman/helper'
import {CheckOutlined, CloseOutlined, EditOutlined} from '@ant-design/icons'
import {NewOfferPriceForm} from './NewOfferPriceForm'
// import {ChangeReturnOrderStatusForm} from './ChangeReturnOrderStatusForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  tabkey: any
  clientOfferData: any
  clientOfferDataTotal: number
  clientOfferLoading: boolean
  setListClientOfferData: (clientOfferData: any) => void
  setListClientOfferDataTotal: (clientOfferDataTotal: number) => void
  setClientOfferLoading: (clientOfferLoading: boolean) => void
  history?: History<LocationState>
  loginUser?: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  clientOfferSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListClientOfferData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      clientOfferSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListClientOfferData(pagination)
    )
  }

  public getListClientOfferData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listClientOfferSearchData(start, end)
        } else {
          this.listClientOfferData(start, end)
        }
      }
    )
  }

  public listClientOfferData = async (start: number, end: any) => {
    const {setClientOfferLoading} = this.props
    try {
      const {setListClientOfferData, setListClientOfferDataTotal, tabkey, loginUser} = this.props
      await setClientOfferLoading(true)
      let clientOfferData = await getClientOfferData(start, end, tabkey,tabkey == 'REVISED' ? true : false)
      if (clientOfferData.data) {
        await setListClientOfferData(clientOfferData.data)
        await setListClientOfferDataTotal(clientOfferData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setClientOfferLoading(false)
    }
  }

  public listClientOfferSearchData = async (start: number, end: any) => {
    const {setClientOfferLoading} = this.props
    try {
      const {setListClientOfferData, setListClientOfferDataTotal, tabkey, loginUser} = this.props
      const {searchText} = this.state

      await setClientOfferLoading(true)
      let clientOfferData = await getClientOfferData(start, end, tabkey,tabkey == 'REVISED' ? true : false)
      if (clientOfferData.data) {
        await setListClientOfferData(clientOfferData.data)
        await setListClientOfferDataTotal(clientOfferData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setClientOfferLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listClientOfferSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      clientOfferSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      clientOfferSelectedData: null,
    })
    this.listClientOfferData(this.state.startIndex, this.state.limitNumber)
  }

  public updateClientOfferAction = async (item: any, status: string) => {
    const {searchText, startIndex, limitNumber} = this.state
    const {setClientOfferLoading} = this.props
    try {
      let reqObj: any = {}
      reqObj.offer_id = item.id ? item.id : ''
      reqObj.status = status

      await setClientOfferLoading(true)

      let returnData = await clientOfferActionApi(reqObj)

      if (returnData && returnData.status == 200) {
        Toaster({
          type: 'success',
          title: 'STOCK OFFER',
          description: 'Action Taken SuccessFully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'STOCK OFFER',
          description: 'Something Went Wrong',
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.listClientOfferData(this.state.startIndex, this.state.limitNumber)

      await setClientOfferLoading(true)
    }
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'Stock Id',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a href={`/inventory/preview/${item.stock.stock_id ? item.stock.stock_id : ''}`} target='_blank'>
              <strong>
                {item.stock && item.stock.stock_id ? returnStockId(item.stock.stock_id) : '-'}
              </strong>
            </a>
          </div>
        )
      },
    },
    {
      title: 'Base Price',
      key: 'base_price',
      render: (text: any, item: any, index: number) => {
        return <div className='fw-bold'>{item.base_price ? returnPriceInThousandFormatWithCurrencySign(item.base_price.toFixed(2)) : '-'}</div>
      },
    },
    // {
    //   title: 'Offer Price',
    //   key: 'offer_price',
    //   render: (text: any, item: any, index: number) => {
    //     return <div className='fw-bold'>{item.offer_price ? item.offer_price.toFixed(2) : '-'}</div>
    //   },
    // },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.shape ? returnStockId(item.stock.shape) : '-'}</div>
      },
    },
    {
      // title: 'Weight',
      title: 'WEIGHT',
      key: 'weight',
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.weight ? item.stock.weight : '-'}</div>
      },
    },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.color ? returnStockId(item.stock.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : '-'}</div>
        )
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      render: (text: any, item: any, index: number) => {
        return <div>{item.cut ? returnStockId(item.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.polish ? returnStockId(item.stock.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : '-'}</div>
        )
      },
    },
    {
      // title: 'Fluorescence Intensity',
      title: 'FL',
      // ellipsis: true,
      key: 'fluorescence_intensity',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.stock && item.stock.fluorescence_intensity
              ? returnStockId(item.stock.fluorescence_intensity)
              : '-'}
          </div>
        )
      },
    },
  ]

  public render() {
    const {clientOfferData, clientOfferDataTotal, clientOfferLoading, loginUser, tabkey} =
      this.props
    const {searchText, clientOfferSelectedData} = this.state
    const {role} = loginUser

    const columnExists = (key: any) => {
      return this.columns.some((column) => column.key === key)
    }

    if (!columnExists('action') && tabkey == 'PENDING') {
      this.columns.push({
        title: 'Actions',
        key: 'action',
        render: (text: any, item: any, index: number) => {
          return (
            <>
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Offer Price'>
                  <Button
                    shape='circle'
                    onClick={() => {
                      this.showEditModal(item)
                    }}
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              </div>
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Reject Request'>
                  <Popconfirm
                    onConfirm={() => {
                      //call delete client api
                      this.updateClientOfferAction(item, 'REJECTED')
                    }}
                    title='Are you sure you want to reject Stock Offer Request?'
                  >
                    <Button shape='circle' icon={<CloseOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Approve Request'>
                  <Popconfirm
                    onConfirm={() => {
                      //call delete client api
                      this.updateClientOfferAction(item, 'ACCEPTED')
                    }}
                    title='Are you sure you want to approve Stock Offer Request?'
                  >
                    <Button shape='circle' icon={<CheckOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            </>
          )
        },
      })
    }

    const offerPriceColumnForAdmin = {
      title: 'Offer Price',
      key: 'offer_price',
      render: (text: any, item: any, index: number) => {
        return <div className='fw-bold'>{item.offer_price ? returnPriceInThousandFormatWithCurrencySign(item.offer_price.toFixed(2)) : '-'}</div>
      },
    }

    const offerPriceColumnForVendor = {
      title: 'Offer Price',
      key: 'updated_offer_price',
      render: (text: any, item: any, index: number) => {
        return (
          <div className='fw-bold'>
            {item.updated_offer_price ? returnPriceInThousandFormatWithCurrencySign(item.updated_offer_price.toFixed(2)) : '-'}
          </div>
        )
      },
    }

    if (!columnExists('offer_price')) {
      this.columns.splice(
        2,
        0,
        role && role == 'super_admin' ? offerPriceColumnForAdmin : offerPriceColumnForVendor
      )
    }

    if (!columnExists('name') && role !== 'vendor') {
      this.columns.splice(3, 0, {
        title: 'Name',
        key: 'Name',
        render: (text: any, item: any, index: number) => {
          return (
            <div>
              <strong>
                {item.user
                  ? item.user.first_name && item.user.last_name
                    ? `${item.user.first_name} ${item.user.last_name}`
                    : item.user.first_name
                    ? item.user.first_name
                    : ''
                  : ''}
              </strong>
            </div>
          )
        },
      })
    }

    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div style={{overflowX: 'auto'}}>
              <ListTable
                rowKey={(record: {id: any}) => record.id}
                onChange={this.handleTableChange}
                onPaginationChange={(e: any) => {
                  if (e - 1) {
                    this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                    return
                  }

                  this.counter = 0
                  this.tempCounter = 0
                }}
                column={this.columns}
                paginationConfig={{
                  total: clientOfferDataTotal,
                  showSizeChanger: true,
                  pageSize: this.state.pagination.pageSize,
                  current: this.state.pagination.current,
                }}
                isLoading={clientOfferLoading}
                dataSource={clientOfferData}
                counter={this.counter}
                tempCounter={this.tempCounter}
                isScroll={true}
                scrollWidth={1400}
              />
              <CommanModal
                show={clientOfferSelectedData ? true : false}
                handleClose={() => {
                  this.closeEditModal()
                }}
                title={'New Offer Price'}
                submitText={'Save Changes'}
              >
                <div>
                  {clientOfferSelectedData && (
                    <NewOfferPriceForm
                      data={clientOfferSelectedData}
                      handleCancel={() => {
                        this.closeEditModal()
                      }}
                      role={role}
                    />
                  )}
                </div>
              </CommanModal>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  clientOfferData: state.clientoffer.clientOfferData,
  clientOfferDataTotal: state.clientoffer.clientOfferDataTotal,
  clientOfferLoading: state.clientoffer.clientOfferLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListClientOfferData: (clientOfferData: any) => {
      dispatch(clientoffer.actions.setClientOfferData(clientOfferData))
    },
    setListClientOfferDataTotal: (clientOfferDataTotal: number) => {
      dispatch(clientoffer.actions.setClientOfferDataTotal(clientOfferDataTotal))
    },
    setClientOfferLoading: (clientOfferLoading: boolean) => {
      dispatch(clientoffer.actions.setClientOfferLoading(clientOfferLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListClientOfferData))
