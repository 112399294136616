import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {OrderModal} from '../models/OrderModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setOrderData: 'SET_ORDER_DATA',
  setOrderDataTotal: 'SET_ORDER_DATA_TOTAL',
  setOrderLoading: 'SET_ORDER_LOADER'
}

const initialOrderState: IOrderState = {
  orderData: undefined,
  orderDataTotal: undefined,
  orderLoading: false
}

export interface IOrderState {
  orderData?: OrderModal
  orderDataTotal?: number
  orderLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'order', whitelist: ['orderData', 'orderDataTotal']},
    (state: IOrderState = initialOrderState, action: ActionWithPayload<IOrderState>) => {
    switch (action.type) {
      case actionTypes.setOrderData: {
        const orderData = action.payload?.orderData
        return {...state, orderData}
      }
            
      case actionTypes.setOrderDataTotal: {
        const orderDataTotal = action.payload?.orderDataTotal
        return {...state, orderDataTotal}
      }
        
      case actionTypes.setOrderLoading: {
        const orderLoading = action.payload?.orderLoading
        return {...state, orderLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setOrderData: (orderData: Array<OrderModal>) => ({type: actionTypes.setOrderData, payload: {orderData : orderData}}),
  setOrderDataTotal: (orderDataTotal: number) => ({ type: actionTypes.setOrderDataTotal, payload: { orderDataTotal } }),
  setOrderLoading: (orderLoading: boolean) => ({type: actionTypes.setOrderLoading, payload: {orderLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setOrderData, function* loginSaga() {
    yield put(actions.setOrderLoading(false))
  })
}
