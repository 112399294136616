/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {DisplaySetting} from './DisaplaySetting'

const ListPage: FC = () => {
  const callInitialDataData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialDataData()
  }, [])

  return (
    <div>
      <DisplaySetting />
    </div>
  )
}

const SettingWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Home',
            path: '/setting',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/setting',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.SETTING'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {SettingWrapper}
