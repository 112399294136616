import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'

interface IRenderFormikForm {
  values: IKycFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IKycFormValues {
  awb_number: string
  courier_company: string
}

interface IKycProps {
  onSubmit: any
  handleCancel: any
}

export class ShipOrderForm extends React.Component<IKycProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IKycFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.awb_number) {
      errors.awb_number = 'AWB Number is required'
    }

    if (!values.courier_company) {
      errors.courier_company = 'Courier Company is required'
    }
    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = async (values: IKycFormValues, action: any) => {
    try {
      this.props.onSubmit(values)
    } catch (e) {
      console.log(e, '!!!!error in api call!!!!')
      throw e
    } finally {
      this.setState({
        visible: false,
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  awb_number: '',
                  courier_company: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-6'>
                          <InputText
                            input={{
                              value: values.awb_number,
                              id: 'awb_number',
                              name: 'awb_number',
                            }}
                            placeholder='Enter AWB Number'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('awb_number', value)
                            }}
                            onBlur={handleBlur}
                            label='AWB Number'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-6'>
                          <InputText
                            input={{
                              value: values.courier_company,
                              id: 'courier_company',
                              name: 'courier_company',
                            }}
                            placeholder='Enter Courier Company'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('courier_company', value)
                            }}
                            onBlur={handleBlur}
                            label='Courier Company'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent onClick={handleSubmit}>Submit</InputButtonComponent>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
