/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as order from '../redux/OrderRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  FilePdfOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import {exportAllOrderDataCsv, getOrderData, getOrderDataForCsv} from '../redux/OrderCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
// import {PreviewOrderForm} from './PreviewOrderForm'
import moment from 'moment'
import {Tag} from 'antd'
import {returnPriceInThousandFormatWithCurrencySign, returnStockId} from '../../../comman/helper'
import {ChangeOrderStatusForm} from './ChangeOrderStatusForm'
import {UploadInvoiceForm} from './UploadInvoiceForm'
import OrderDetailsCsv from './OrderDetailsCsv'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  tabkey: any
  orderData: any
  orderDataTotal: number
  orderLoading: boolean
  setListOrderData: (orderData: any) => void
  setListOrderDataTotal: (orderDataTotal: number) => void
  setOrderLoading: (orderLoading: boolean) => void
  history?: History<LocationState>
  loginUser?: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  orderSelectedData: any
  dataTableSelectedRowKeys: any
  selectedRowsData: any
  changeStatusModalState: any
  invoiceData: any
  orderDetailsModal: any
}

type PathParamsType = {
  param1: string
}
class ListOrderData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      orderSelectedData: null,
      changeStatusModalState: null,
      invoiceData: null,
      dataTableSelectedRowKeys: [],
      selectedRowsData: [],
      orderDetailsModal: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListOrderData(pagination)
    )
  }

  public getListOrderData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listOrderSearchData(start, end)
        } else {
          this.listOrderData(start, end)
        }
      }
    )
  }

  public listOrderData = async (start: number, end: any) => {
    const {setOrderLoading} = this.props
    try {
      const {setListOrderData, setListOrderDataTotal, tabkey} = this.props
      await setOrderLoading(true)
      let orderData = await getOrderData(start, end, tabkey)
      if (orderData.data) {
        await setListOrderData(orderData.data)
        await setListOrderDataTotal(orderData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setOrderLoading(false)
    }
  }

  public listOrderSearchData = async (start: number, end: any) => {
    const {setOrderLoading} = this.props
    try {
      const {setListOrderData, setListOrderDataTotal, tabkey} = this.props
      const {searchText} = this.state

      await setOrderLoading(true)
      let orderData = await getOrderData(start, end, tabkey)
      if (orderData.data) {
        await setListOrderData(orderData.data)
        await setListOrderDataTotal(orderData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setOrderLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listOrderSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateOrder = () => {
    const {history} = this.props
    history.push('/order/create')
  }

  public showPreviewModal = async (item: any) => {
    this.setState({
      orderSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      orderSelectedData: null,
    })
    this.listOrderData(this.state.startIndex, this.state.limitNumber)
  }

  public showInvoiceModal = async (item: any) => {
    this.setState({
      invoiceData: item,
    })
  }

  public closeInvoiceModal = async () => {
    this.setState({
      invoiceData: null,
    })
    this.listOrderData(this.state.startIndex, this.state.limitNumber)
  }

  public showStatusModal = async (item: any) => {
    this.setState({
      changeStatusModalState: item,
    })
  }

  public closeStatusModal = async () => {
    this.setState({
      changeStatusModalState: null,
    })
    this.setState({
      dataTableSelectedRowKeys: [],
      selectedRowsData: [],
    })
    this.listOrderData(this.state.startIndex, this.state.limitNumber)
  }

  public showOrderDetailsModal = async (item: any) => {
    this.setState({
      orderDetailsModal: item,
    })
  }

  public closeOrderDetailsModal = async () => {
    this.setState({
      orderDetailsModal: null,
    })
  }

  public navigateToPreviewOrderPage = async (item: any) => {
    const {history} = this.props
    history.push(`${process.env.PUBLIC_URL}/order/preview/` + item.id)
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Order Code',
      key: 'order_code',
      width: 130,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.order_code ? returnStockId(item.order_code) : '-'}</strong>
          </div>
        )
      },
    },
    // {
    //   // title: 'Stock Id',
    //   title: 'STOCK ID',
    //   key: 'Stock Id',
    //   width: 80,
    //   // fixed: window.innerWidth < 768 ? false : true,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //           {item.stock && item.stock.stock_id ? returnStockId(item.stock.stock_id) : '-'}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: 'Status',
    //   key: 'order_status',
    //   fixed: window.innerWidth < 768 ? false : true,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.order_status ? (
    //           <Tag
    //             color={
    //               config.statusTagColor[item.order_status]
    //                 ? config.statusTagColor[item.order_status]
    //                 : 'default'
    //             }
    //           >
    //             {item.order_status}
    //           </Tag>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Full Name',
      key: 'Full Name',
      fixed: window.innerWidth < 768 ? false : true,
      sorter: (a: any, b: any) => {
        const fullNameA = `${a.user_details?.first_name || ''} ${
          a.user_details?.last_name || ''
        }`.trim()
        const fullNameB = `${b.user_details?.first_name || ''} ${
          b.user_details?.last_name || ''
        }`.trim()
        return fullNameA.localeCompare(fullNameB)
      },
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.user_details?.first_name && item?.user_details?.last_name
                ? `${item.user_details.first_name} ${item.user_details.last_name}`
                : item?.user_details?.first_name
                ? item?.user_details?.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: 130,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item?.user_details ? (item?.user_details?.email ? item.user_details.email : '-') : '-'}
          </div>
        )
      },
    },
    {
      title: 'Type',
      key: 'type',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item && item.type ? (
              <Tag color={item.type == 'OFFER' ? 'green' : 'blue'}>{item.type}</Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Order Date',
      key: 'createdAt',
      sorter: (a: any, b: any) => {
        const dateA: any = new Date(a.createdAt)
        const dateB: any = new Date(b.createdAt)
        return dateA - dateB
      },
      render: (text: any, item: any, index: number) => {
        return <div>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : '-'}</div>
      },
    },
    {
      title: 'Invoice',
      key: 'invoice_number',
      width: 180,
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{fontWeight: 500}}>
            {item.invoice_number && item.invoice_url ? (
              <a target='blank' href={item.invoice_url}>
                {item.invoice_number ? item.invoice_number : '-'}
              </a>
            ) : item.invoice_number ? (
              <div>{item.invoice_number ? item.invoice_number : '-'}</div>
            ) : (
              <Tag color='red'>No Invoice</Tag>
            )}
          </div>
        )
      },
    },
    {
      title: 'Awb Number',
      key: 'awb_number',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.awb_number? item.awb_number : '-'}
          </div>
        )
      },
    },
    {
      title: 'Courier Company',
      key: 'courier_company',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.courier_company? item.courier_company : '-'}
          </div>
        )
      },
    }, 
    {
      title: 'Quantity',
      key: 'billing_address',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.buy_request_details && item.buy_request_details.stock_ids
              ? item.buy_request_details.stock_ids.length
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Total Cts',
      key: 'total_cts',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.buy_request_details && item.buy_request_details.total_cts
              ? item.buy_request_details.total_cts.toFixed(2)
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Price/Carat',
      key: 'price_per_carat',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.buy_request_details && item.buy_request_details.price_per_carat
              ? returnPriceInThousandFormatWithCurrencySign(
                  item.buy_request_details.price_per_carat.toFixed(2)
                )
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Shipment Price',
      key: 'shipment_price',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.shipment_price
              ? returnPriceInThousandFormatWithCurrencySign(item.shipment_price.toFixed(2))
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Total Amount',
      key: 'amount',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.amount
              ? returnPriceInThousandFormatWithCurrencySign(item.amount.toFixed(2))
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Tax Amount',
      key: 'tax_price',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.tax_price
              ? returnPriceInThousandFormatWithCurrencySign(item.tax_price.toFixed(2))
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Grand Amount',
      key: 'grand_total',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.buy_request_details && item.buy_request_details.grand_total
              ? returnPriceInThousandFormatWithCurrencySign(
                  item.buy_request_details.grand_total.toFixed(2)
                )
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Preview'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    // this.showPreviewModal(item)
                    this.navigateToPreviewOrderPage(item)
                  }}
                />
              </Tooltip>
            </div>
            {this.props.tabkey == 'SHIPPED' && (
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Order Details'>
                  <Button
                    shape='circle'
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      this.showOrderDetailsModal(item)
                    }}
                  />
                </Tooltip>
              </div>
            )}
            {this.props.tabkey == 'DELIVERED' && (
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Upload Invoice'>
                  <Button
                    shape='circle'
                    icon={<FilePdfOutlined />}
                    onClick={() => {
                      this.showInvoiceModal(item)
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  rowSelection = {
    onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
      this.setState({dataTableSelectedRowKeys: selectedRowKeys, selectedRowsData: selectedRows})
    },
  }

  public generateCsv = async () => {
    try {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          exportAllOrderDataCsv(this.props.tabkey)
            .then((res: any) => {
              let csvArray = []

              csvArray.push([
                'ORDER CODE',
                'FULL NAME',
                'EMAIL',
                'TYPE',
                'ORDER DATE',
                'INVOICE',
                'QUANTITY',
                'TOTAL CTS',
                'PRICE/CARAT',
                'SHIPMENT PRICE',
                'TOTAL AMOUNT',
                'TAX PRICE',
                'GRAND TOTAL',
              ])

              res.data.map((item: any) => {
                csvArray.push([
                  item && item.order_code ? returnStockId(item.order_code) : 'NA',
                  item && item.user_details
                    ? item.user_details.first_name && item.user_details.last_name
                      ? `${item.user_details.first_name} ${item.user_details.last_name}`
                      : item.user_details.first_name
                      ? item.user_details.first_name
                      : '-'
                    : 'NA',
                  item && item.user_details && item.user_details.email
                    ? item.user_details.email
                    : 'NA',
                  item && item.type ? item.type : 'NA',
                  item && item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : 'NA',
                  item && item.invoice_number ? item.invoice_number : 'NA',
                  item && item.buy_request_details && item.buy_request_details.stock_ids
                    ? item.buy_request_details.stock_ids.length
                    : '0',
                  item && item.buy_request_details && item.buy_request_details.total_cts
                    ? item.buy_request_details.total_cts.toFixed(2)
                    : '0',
                  item && item.buy_request_details && item.buy_request_details.price_per_carat
                    ? returnPriceInThousandFormatWithCurrencySign(
                        item.buy_request_details.price_per_carat.toFixed(2)
                      )
                    : '0',
                  item && item.shipment_price
                    ? returnPriceInThousandFormatWithCurrencySign(item.shipment_price.toFixed(2))
                    : '0',
                  item && item.amount
                    ? returnPriceInThousandFormatWithCurrencySign(item.amount.toFixed(2))
                    : '0',
                    item && item.tax_price
                    ? returnPriceInThousandFormatWithCurrencySign(item.tax_price.toFixed(2))
                    : '0',
                  item && item.buy_request_details && item.buy_request_details.grand_total
                    ? returnPriceInThousandFormatWithCurrencySign(
                        item.buy_request_details.grand_total.toFixed(2)
                      )
                    : '0',
                ])
              })

              const rows = csvArray
              let csvName = 'orders.csv'
              let csvContent = 'data:text/csv;charset=utf-8,'

              rows.forEach(function (rowArray) {
                let row = rowArray.join(',')
                csvContent += row + '\r\n'
              })

              var encodedUri = encodeURI(csvContent)
              // // // // window.open(encodedUri);
              var link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', csvName)
              document.body.appendChild(link) // Required for FF

              link.click()

              this.setState({
                isLoading: false,
              })
            })
            .catch((err) => {
              console.log('ERROR', err)

              this.setState({
                isLoading: true,
              })

              throw err
            })
        }
      )
    } catch (e) {
      console.log('error in generate csv', e)
      this.setState({
        isLoading: true,
      })
    }
  }

  public render() {
    const {orderData, orderDataTotal, orderLoading, loginUser, tabkey} = this.props
    const {
      searchText,
      orderSelectedData,
      selectedRowsData,
      dataTableSelectedRowKeys,
      changeStatusModalState,
      invoiceData,
      orderDetailsModal,
    } = this.state
    const {role} = loginUser

    return (
      <div>
        <div>
          {(role == 'super_admin' || role == 'sub_admin') &&
            tabkey == 'PENDING' &&
            dataTableSelectedRowKeys &&
            !!dataTableSelectedRowKeys.length && (
              <div className='row justify-content-end' style={{marginRight: '20px'}}>
                <Button
                  className='m-5 rounded'
                  style={{width: '100px'}}
                  type='primary'
                  onClick={this.showStatusModal.bind(this)}
                >
                  UPDATE
                </Button>
              </div>
            )}
          {(role == 'super_admin' || role == 'sub_admin') && (
            <div className='row justify-content-end' style={{marginRight: '20px'}}>
              <Button
                className='mb-5 rounded'
                style={{width: '100px'}}
                type='primary'
                onClick={() => {
                  this.generateCsv()
                }}
              >
                EXPORT
              </Button>
            </div>
          )}
          <div className='card card-custom'>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  column={this.columns}
                  paginationConfig={{
                    total: orderDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={orderLoading}
                  dataSource={orderData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={1750}
                  // rowSelection={
                  //   (loginUser?.role !== 'super_admin' || loginUser?.role !== 'sub_admin')
                  //     ? {
                  //         type: 'checkbox',
                  //         ...this.rowSelection,
                  //         selectedRowKeys: dataTableSelectedRowKeys ? dataTableSelectedRowKeys : [],
                  //       }
                  //     : undefined
                  // }
                />
                <div>
                  {/* <CommanModal
                    show={orderSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Preview Order'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {orderSelectedData && (
                        <PreviewOrderForm
                          data={orderSelectedData}
                          loginUserData={loginUser}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal> */}
                  <CommanModal
                    show={changeStatusModalState ? true : false}
                    handleClose={() => {
                      this.closeStatusModal()
                    }}
                    title={'Order Status'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {changeStatusModalState && (
                        <ChangeOrderStatusForm
                          data={selectedRowsData}
                          rowIds={dataTableSelectedRowKeys}
                          handleCancel={() => {
                            this.closeStatusModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                  <CommanModal
                    show={invoiceData ? true : false}
                    handleClose={() => {
                      this.closeInvoiceModal()
                    }}
                    title={'Invoice'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {invoiceData && (
                        <UploadInvoiceForm
                          data={invoiceData}
                          handleCancel={() => {
                            this.closeInvoiceModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                  <CommanModal
                    show={orderDetailsModal ? true : false}
                    handleClose={() => {
                      this.closeOrderDetailsModal()
                    }}
                    title={'Download Order Details'}
                    submitText={'Save Changes'}
                    isScrollable={true}
                  >
                    <div>
                      {orderDetailsModal && (
                        <OrderDetailsCsv
                          orderData={orderDetailsModal}
                          handleCancel={() => {
                            this.closeOrderDetailsModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  orderData: state.order.orderData,
  orderDataTotal: state.order.orderDataTotal,
  orderLoading: state.order.orderLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListOrderData: (orderData: any) => {
      dispatch(order.actions.setOrderData(orderData))
    },
    setListOrderDataTotal: (orderDataTotal: number) => {
      dispatch(order.actions.setOrderDataTotal(orderDataTotal))
    },
    setOrderLoading: (orderLoading: boolean) => {
      dispatch(order.actions.setOrderLoading(orderLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListOrderData))
