/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as inventory from '../../inventory/redux/InventoryRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {getSearchSuggestInventoryData} from '../../inventory/redux/InventoryCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
// import {IInventoryFormValues} from './CreateInventoryForm'
import {Tag} from 'antd'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
} from '../../../comman/helper'
import {Spin} from 'antd'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  inventoryData: any
  inventoryDataTotal: number
  inventoryLoading: boolean
  inventoryCartData: any
  setListInventoryData: (inventoryData: any) => void
  setListInventoryDataTotal: (inventoryDataTotal: number) => void
  setInventoryLoading: (inventoryLoading: boolean) => void
  setInventoryCartData: (inventoryCartData: any) => void
  history?: History<LocationState>
  loginUser: any
  data?: any
  onSubmit?: any
  handleCancel: any
  suggestedStocksState: any
  stockInforrmatiomMapObject: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  inventorySelectedData: any
  dataTableSelectedRowKeys: any
  dataTableSelectedRowsData: any
  suggestStockInformation: any
}

type PathParamsType = {
  param1: string
}
class ListInventoryData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      inventorySelectedData: null,
      dataTableSelectedRowKeys: [],
      dataTableSelectedRowsData: null,
      suggestStockInformation: null,
    }
  }

  tempBuyRequests: any = []

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListInventoryData(pagination)
    )
  }

  public componentDidMount = () => {
    this.listInventorySearchData(0, 10)

    const {suggested_stock} = this.props.data
    console.log(suggested_stock, 'suggested_stock')
    if (suggested_stock) {
      const suggestedStockDataKeys: any = []
      if (suggested_stock && suggested_stock.stock_id) {
        suggestedStockDataKeys.push(suggested_stock.stock_id)
      }
      const suggestedStockData = suggested_stock ? suggested_stock : {}

      let stockInforrmatiomData = {}
      if (this.props.data && this.props.data.stock_id && this.props.stockInforrmatiomMapObject) {
        stockInforrmatiomData = this.props.stockInforrmatiomMapObject[this.props.data.stock_id]
          ? this.props.stockInforrmatiomMapObject[this.props.data.stock_id]
          : {}
      }
      this.setState({
        dataTableSelectedRowKeys: suggestedStockDataKeys,
        dataTableSelectedRowsData: suggestedStockData,
        suggestStockInformation: stockInforrmatiomData
      })
    }
  }

  public getListInventoryData = async (pagination: any) => {
    const {searchText} = this.state

    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listInventorySearchData(start, end)
        } else {
          this.listInventoryData(start, end)
        }
      }
    )
  }

  public listInventoryData = async (start: number, end: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {setListInventoryData, setListInventoryDataTotal} = this.props
      await setInventoryLoading(true)
      let inventoryData = await getSearchSuggestInventoryData(start, end, {status: ['available']})
      if (inventoryData.data) {
        await setListInventoryData(inventoryData.data)
        await setListInventoryDataTotal(inventoryData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
      this.setState({
        isLoading: false,
      })
    }
  }

  public listInventorySearchData = async (start: number, end: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {setListInventoryData, setListInventoryDataTotal} = this.props
      const {searchText} = this.state

      await setInventoryLoading(true)
      let inventoryData = await getSearchSuggestInventoryData(start, end, {
        certificationId: searchText,
        status: ['available'],
      })
      if (inventoryData.data) {
        await setListInventoryData(inventoryData.data)
        await setListInventoryDataTotal(inventoryData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
      this.setState({
        isLoading: false,
      })
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listInventorySearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  counter = 0
  tempCounter = 0
  public columns = [
    // {
    //   title: 'Sr. No.',
    //   key: 'serial_number',
    // fixed: window.innerWidth < 768 ? false : true,
    //   width: 60,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <span>{++index + this.tempCounter}</span>
    //       </div>
    //     )
    //   },
    // },
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'Stock Id',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a href={`/inventory/preview/${item.stock_id}`} target='_blank'>
              <strong>{item.stock_id ? returnStockId(item.stock_id) : '-'}</strong>
            </a>
          </div>
        )
      },
    },
    {
      // title: 'Stock Id',
      title: 'VENDOR',
      key: 'vendor_details',
      // width: 150,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.vendor_details
              ? item.vendor_details.first_name && item.vendor_details.last_name
                ? `${item.vendor_details.first_name} ${item.vendor_details.last_name}`
                : item.vendor_details.first_name
                ? item.vendor_details.first_name
                : '-'
              : '-'}
          </div>
        )
      },
    },
    {
      // title: 'Status',
      title: 'STATUS',
      key: 'status',
      width: 80,
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      // title: 'Image',
      title: 'MEDIA',
      key: 'diamond_image',
      width: 150,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.diamond_image ? (
              <a className='text-muted' href={item.diamond_image} target='_blank'>
                {/* {item.diamond_image} */}
                image
              </a>
            ) : (
              <span className='text-muted'>{'No Image'}</span>
            )}
            {' | '}
            {item.diamond_video ? (
              <a className='text-muted' href={item.diamond_video} target='_blank'>
                {/* {item.diamond_video} */}
                video
              </a>
            ) : (
              <span className='text-muted'>{'No Video'}</span>
            )}
          </div>
        )
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.shape ? returnStockId(item.shape) : '-'}</div>
      },
    },
    // {
    //   // title: 'Weight',
    //   title: 'WEIGHT',
    //   key: 'weight',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.weight ? item.weight : '-'}</div>
    //   },
    // },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.color ? returnStockId(item.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return <div>{item.clarity ? returnStockId(item.clarity) : '-'}</div>
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.cut ? returnStockId(item.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.polish ? returnStockId(item.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.symmetry ? returnStockId(item.symmetry) : '-'}</div>
      },
    },

    {
      // title: 'Fluorescence Intensity',
      title: 'FL',
      // ellipsis: true,
      key: 'fluorescence_intensity',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.fluorescence_intensity ? returnStockId(item.fluorescence_intensity) : '-'}
          </div>
        )
      },
    },
    {
      // title: 'Lab Grown Type',
      title: 'LAB',
      // ellipsis: true,
      key: 'lab_grown_type',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.lab_grown_type ? item.lab_grown_type : '-'}</div>
      },
    },
    {
      // title: 'Certificate No.',
      title: 'CERTIFICATE',
      key: 'certificate_number',
      width: 100,
      // ellipsis: true,
      render: (text: any, item: any, index: number) => {
        return <div>{item.certificate_number ? item.certificate_number : '-'}</div>
      },
    },
    {
      // title: 'Discounts',
      title: 'DISCOUNTS',
      // ellipsis: true,
      key: 'discounts',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return <div>{item.discounts ? item.discounts : '-'}</div>
      },
    },
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return <div>{item.price_per_caret ? item.price_per_caret : '-'}</div>
      },
    },
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.final_price ? returnPriceInThousandFormatWithCurrencySign(item.final_price) : '-'}
          </div>
        )
      },
    },
  ]

  rowSelection = {
    onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
      const newSelectedRows = selectedRows.filter((row: any) => row)
      // let filteredRows = []
      // if (newSelectedRows && newSelectedRows.length) {
      //   filteredRows = newSelectedRows.map((row: any) => ({
      //     stock_id: row.id,
      //     vendor_id: row.vendor_id,
      //     is_available: row.is_available !== undefined ? row.is_available : true,
      //     is_action_taken: row.is_action_taken !== undefined ? row.is_action_taken : false,
      //   }))
      // }

      let selectedRowData = {}
      let stockInfo = {}
      if (newSelectedRows && newSelectedRows.length) {
        const row = newSelectedRows[0]
        stockInfo = row
        const id = row.vendor_id ? row.vendor_id : row.admin_id ? row.admin_id : null
        selectedRowData = {
          stock_id: row.id,
          [row.vendor_id ? 'vendor_id' : 'admin_id']: id,
          is_available: row.is_available !== undefined ? row.is_available : true,
          is_action_taken: row.vendor_id ? false : true,
        }
      }

      this.setState({
        dataTableSelectedRowKeys: selectedRowKeys,
        // dataTableSelectedRowsData: filteredRows,
        dataTableSelectedRowsData: selectedRowData,
        suggestStockInformation: stockInfo,
      })
    },

    getCheckboxProps: (record: any) => ({
      disabled: record.status !== 'AVAILABLE', // Column configuration not to be checked
      status: record.status, // se the particular column disable and update the table
    }),
  }

  public render() {
    const {
      inventoryData,
      inventoryDataTotal,
      inventoryLoading,
      loginUser,
      inventoryCartData,
      suggestedStocksState,
    } = this.props
    const {
      searchText,
      inventorySelectedData,
      dataTableSelectedRowKeys,
      dataTableSelectedRowsData,
      suggestStockInformation,
    } = this.state

    // Filter available stocks excluding already suggested stocks but not exclude one which is suggested for this stock only

    // check if their is already suggested Stock
    const {suggested_stock} = this.props.data

    let selectedSuggestedStockId = ''
    if (suggested_stock && suggested_stock.stock_id) {
      selectedSuggestedStockId = suggested_stock.stock_id
    }

    let availableStocks = []
    if (inventoryData) {
      availableStocks = inventoryData.filter((stock: any) => {
        return !suggestedStocksState.includes(stock.id) || stock.id == selectedSuggestedStockId
      })
    }
    const inventoryAvailableStocksLength =
      availableStocks && availableStocks.length ? availableStocks.length : 0
    return (
      <div>
        <div>
          {!this.state.isLoading ? (
            <div className='card card-custom inventory-list'>
              <div className='card-header justify-content-between align-items-center'>
                <h3 className='card-title table-card-title' style={{width: '50%'}}>
                  <InputSearchText
                    onChange={(value: string) => {
                      this.onSearchChange(value)
                    }}
                    placeholder='Search Inventory'
                    input={{
                      value: searchText,
                    }}
                    style={{width: '500px'}}
                  />
                </h3>
                <div>
                  {!!dataTableSelectedRowKeys.length && (
                    <InputButtonComponent
                      onClick={() => {
                        this.props.onSubmit(
                          this.props.data.stock_id,
                          dataTableSelectedRowsData,
                          suggestStockInformation
                        )
                      }}
                    >
                      Suggest
                    </InputButtonComponent>
                  )}
                </div>
              </div>
              <div className='card-body'>
                <div>
                  <ListTable
                    rowKey={(record: {id: any}) => record.id}
                    onChange={this.handleTableChange}
                    onPaginationChange={(e: any) => {
                      if (e - 1) {
                        this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                        return
                      }

                      this.counter = 0
                      this.tempCounter = 0
                    }}
                    column={this.columns}
                    paginationConfig={{
                      total: inventoryAvailableStocksLength,
                      showSizeChanger: true,
                      pageSize: this.state.pagination.pageSize,
                      current: this.state.pagination.current,
                    }}
                    isLoading={inventoryLoading}
                    dataSource={availableStocks}
                    counter={this.counter}
                    tempCounter={this.tempCounter}
                    isScroll={true}
                    scrollWidth={1350}
                    rowSelection={
                      loginUser?.role !== 'admin'
                        ? {
                            type: 'radio',
                            ...this.rowSelection,
                            preserveSelectedRowKeys: true,
                            selectedRowKeys: dataTableSelectedRowKeys
                              ? dataTableSelectedRowKeys
                              : [],
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <Spin style={{margin: 'auto 45%'}} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  inventoryData: state.inventory.inventoryData,
  inventoryDataTotal: state.inventory.inventoryDataTotal,
  inventoryLoading: state.inventory.inventoryLoading,
  inventoryCartData: state.inventory.inventoryCartData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListInventoryData: (inventoryData: any) => {
      dispatch(inventory.actions.setInventoryData(inventoryData))
    },
    setListInventoryDataTotal: (inventoryDataTotal: number) => {
      dispatch(inventory.actions.setInventoryDataTotal(inventoryDataTotal))
    },
    setInventoryLoading: (inventoryLoading: boolean) => {
      dispatch(inventory.actions.setInventoryLoading(inventoryLoading))
    },
    setInventoryCartData: (inventoryCartData: any) => {
      dispatch(inventory.actions.setInventoryCartData(inventoryCartData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListInventoryData))
