import {FC, useEffect, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as banner from './BannerRedux'
import {RootState} from '../../../../setup'

const mapState = (state: RootState) => ({banner: state.banner})
const connector = connect(mapState, banner.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const BannerInit: FC<PropsFromRedux> = (props) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken before rendering the application
  useEffect(() => {}, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(BannerInit)
